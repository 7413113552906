<template>
    <div class="content">
        <AboutHeader />
        <AboutHistory />
        <AboutTrusted />
        <AboutTeam />
    </div>
</template>

<script setup>
    import AboutHeader from '@/components/about/AboutHeader.vue'
    import AboutHistory from '@/components/about/AboutHistory.vue'
    import AboutTrusted from '@/components/about/AboutTrusted.vue'
    import AboutTeam from '@/components/about/AboutTeam.vue'
    document.title = 'About - P3K Melinda Hospital';
</script>