<template>
    <div class="content">
      <ArticleCategoryBrowse />
    </div>
</template>

<script>
import ArticleCategoryBrowse from '@/components/article/ArticleCategoryBrowse.vue'
export default {
  name: 'ArticleCategoryPage',
  components: {
    ArticleCategoryBrowse
  },
  data() {
    return {
      products: [],
    };
  },
  methods: {
    setProducts(data) {
      this.products = data;
    },
  },
  setup() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
  }
}
</script>