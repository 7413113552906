import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import ExperimentPage from '../views/ExperimentPage.vue'
import ExplorePage from '../views/ExplorePage.vue'
import ArticlePage from '../views/ArticlePage.vue'
import ArticleCategoryPage from '../views/ArticleCategoryPage.vue'
import ArticleDetailPage from '../views/ArticleDetailPage.vue'
import ExploreCategoryPage from '../views/ExploreCategoryPage.vue'
import AboutPage from '../views/AboutPage.vue'
import LinkPage from '../views/LinkPage.vue'
import ContactPage from '../views/ContactPage.vue'
import FirstAidDetailPage from '../views/FirstAidDetailPage.vue'
import MedicinePage from '../views/MedicinePage.vue'
import MedicineDetailPage from '../views/MedicineDetailPage.vue'
import NotFoundPage from '../views/NotFoundPage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/explore',
    name: 'ExplorePage',
    component: ExplorePage
  },
  {
    path: '/explore/category/:id',
    name: 'ExploreCategoryPage',
    component: ExploreCategoryPage
  },
  {
    path: '/explore/detail/:id',
    name: 'FirstAidDetailPage',
    component: FirstAidDetailPage
  },
  {
    path: '/obat',
    name: 'MedicinePage',
    component: MedicinePage
  },
  {
    path: '/obat/detail/:id',
    name: 'MedicineDetailPage',
    component: MedicineDetailPage
  },
  {
    path: '/article',
    name: 'ArticlePage',
    component: ArticlePage
  },
  {
    path: '/article/category/:id',
    name: 'ArticleCategoryPage',
    component: ArticleCategoryPage
  },
  {
    path: '/article/detail/:id',
    name: 'ArticleDetailPage',
    component: ArticleDetailPage
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/links',
    name: 'LinkPage',
    component: LinkPage
  },
  {
    path: '/contact-us',
    name: 'ContactPage',
    component: ContactPage
  },
  {
    path: '/not-found',
    name: 'NotFoundPage',
    component: NotFoundPage
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/not-found'
  },
  {
    path: '/experiment',
    name: 'ExperimentPage',
    component: ExperimentPage
  },
  {
    path: '/about-x',
    name: 'about-x',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
