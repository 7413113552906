import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useArticleStore = defineStore("article", {
  state: () => ({
    articles: null,
    articleNum: 1,
    articleDetail: null,
    articleDetailTitle: 'Article Detail',
    articleCategories: null,
    //===============
    articleDetailRelated: [],
    articlesTpp: [],
    articlesPdr: [],
    articlesStr: [],
    articlesSrj: [],
    articlesPtt: [],
    articlesTsd: [],
    articlesCdk: [],
    articlesLkb: [],
    articlesEpl: [],
    articlesDbt: [],
    articlesKrc: [],
    articlesSra: [],
    articlesPny: [],
    //===============
    articlesByCategory: [],
    articlesHb1: null,
    articlesHb2: [],
    articlesHb3: [],
    articlesHb4: [],
    articlesHb5: [],
    articlesHb6: [],
    articlesHb7: [],
    articlesHb8: [],
    articlesHb9: [],
    articlesHb10: [],
    articlesHb11: [],
    articlesHb12: [],
    articlesHb13: [],
    articlesHb14: [],
    atxeb1: null,
    atxeb2: null,
    atxeb3: null,
    atxeb4: null,
    atxeb5: null,
    atxeb6: null,
    articlesMix: null,
    articleBtBig: null,
    articleBtRight: null
  }),
  getters: {
    articlesTppTop: (state) => {
      return state.articlesTpp.slice(0, 2);
    },
    articleTppBig: (state) => {
      return state.articlesTpp.slice(2, 3);
    },
    articlesTppBtr: (state) => {
      return state.articlesTpp.slice(3, 5);
    },
    articlesPdrTop: (state) => {
      return state.articlesPdr.slice(0, 2);
    },
    articlePdrBig: (state) => {
      return state.articlesPdr.slice(2, 3);
    },
    articlesPdrBtr: (state) => {
      return state.articlesPdr.slice(3, 5);
    },
    articlesStrTop: (state) => {
      return state.articlesStr.slice(0, 2);
    },
    articleStrBig: (state) => {
      return state.articlesStr.slice(2, 3);
    },
    articlesStrBtr: (state) => {
      return state.articlesStr.slice(3, 5);
    },
    articlesSrjTop: (state) => {
      return state.articlesSrj.slice(0, 2);
    },
    articleSrjBig: (state) => {
      return state.articlesSrj.slice(2, 3);
    },
    articlesSrjBtr: (state) => {
      return state.articlesSrj.slice(3, 5);
    },
    articlesPttTop: (state) => {
      return state.articlesPtt.slice(0, 2);
    },
    articlePttBig: (state) => {
      return state.articlesPtt.slice(2, 3);
    },
    articlesPttBtr: (state) => {
      return state.articlesPtt.slice(3, 5);
    },
    articlesTsdTop: (state) => {
      return state.articlesTsd.slice(0, 2);
    },
    articleTsdBig: (state) => {
      return state.articlesTsd.slice(2, 3);
    },
    articlesTsdBtr: (state) => {
      return state.articlesTsd.slice(3, 5);
    },
    articlesCdkTop: (state) => {
      return state.articlesCdk.slice(0, 2);
    },
    articleCdkBig: (state) => {
      return state.articlesCdk.slice(2, 3);
    },
    articlesCdkBtr: (state) => {
      return state.articlesCdk.slice(3, 5);
    },
    articlesLkbTop: (state) => {
      return state.articlesLkb.slice(0, 2);
    },
    articleLkbBig: (state) => {
      return state.articlesLkb.slice(2, 3);
    },
    articlesLkbBtr: (state) => {
      return state.articlesLkb.slice(3, 5);
    },
    articlesEplTop: (state) => {
      return state.articlesEpl.slice(0, 2);
    },
    articleEplBig: (state) => {
      return state.articlesEpl.slice(2, 3);
    },
    articlesEplBtr: (state) => {
      return state.articlesEpl.slice(3, 5);
    },
    articlesDbtTop: (state) => {
      return state.articlesDbt.slice(0, 2);
    },
    articleDbtBig: (state) => {
      return state.articlesDbt.slice(2, 3);
    },
    articlesDbtBtr: (state) => {
      return state.articlesDbt.slice(3, 5);
    },
    articlesKrcTop: (state) => {
      return state.articlesKrc.slice(0, 2);
    },
    articleKrcBig: (state) => {
      return state.articlesKrc.slice(2, 3);
    },
    articlesKrcBtr: (state) => {
      return state.articlesKrc.slice(3, 5);
    },
    articlesSraTop: (state) => {
      return state.articlesSra.slice(0, 2);
    },
    articleSraBig: (state) => {
      return state.articlesSra.slice(2, 3);
    },
    articlesSraBtr: (state) => {
      return state.articlesSra.slice(3, 5);
    },
    articlesPnyTop: (state) => {
      return state.articlesPny.slice(0, 2);
    },
    articlePnyBig: (state) => {
      return state.articlesPny.slice(2, 3);
    },
    articlesPnyBtr: (state) => {
      return state.articlesPny.slice(3, 5);
    },
    adRelated: (state) => {
      return state.articleDetailRelated.slice(0, 2);
    }
  },
  actions: {
    setArticleNum(nm){
      this.articleNum = nm
    },
    async fetchArticles(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articles = response.data;
    },
    async fetchArticleDetail(id) {
      this.articleDetail = false;
      const response = await axiosClient.get(`artikel/detail/` + id);
      this.articleDetail = response.data;
      // this.articleDetailTitle = response.data[0].title;
    },
    async fetchArticleCategories() {
      const response = await axiosClient.get(`artikel/get-categories`);
      this.articleCategories = response.data;
    },
    async fetchArticlesMix(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articlesMix = response.data;
    },
    async fetchArticleBtBig(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articleBtBig = response.data;
    },
    async fetchArticleBtRight(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articleBtRight = response.data;
    },
    //===============
    async fetchTpp(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesTpp = response.data;
    },
    async fetchPdr(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesPdr = response.data;
    },
    async fetchStr(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesStr = response.data;
    },
    async fetchSrj(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesSrj = response.data;
    },
    async fetchPtt(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesPtt = response.data;
    },
    async fetchTsd(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesTsd = response.data;
    },
    async fetchCdk(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesCdk = response.data;
    },
    async fetchLkb(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesLkb = response.data;
    },
    async fetchEpl(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesEpl = response.data;
    },
    async fetchDbt(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesDbt = response.data;
    },
    async fetchKrc(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesKrc = response.data;
    },
    async fetchSra(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesSra = response.data;
    },
    async fetchPny(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesPny = response.data;
    },
    async fetchAdr(id) {
      const response = await axiosClient.get(`artikel/related/` + id);
      this.articleDetailRelated = response.data;
    },
    //===============
    async fetchArticlesByCategory(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesByCategory = response.data;
    },
    async fetchArticlesHb1(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb1 = response.data;
    },
    async fetchArticlesHb2(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb2 = response.data;
    },
    async fetchArticlesHb3(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb3 = response.data;
    },
    async fetchArticlesHb4(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb4 = response.data;
    },
    async fetchArticlesHb5(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb5 = response.data;
    },
    async fetchArticlesHb6(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb6 = response.data;
    },
    async fetchArticlesHb7(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb7 = response.data;
    },
    async fetchArticlesHb8(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb8 = response.data;
    },
    async fetchArticlesHb9(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb9 = response.data;
    },
    async fetchArticlesHb10(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb10 = response.data;
    },
    async fetchArticlesHb11(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb11 = response.data;
    },
    async fetchArticlesHb12(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb12 = response.data;
    },
    async fetchArticlesHb13(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb13 = response.data;
    },
    async fetchArticlesHb14(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.articlesHb14 = response.data;
    },
    async fetchAtxkc1(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.atxeb1 = response.data;
    },
    async fetchAtxkc2(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.atxeb2 = response.data;
    },
    async fetchAtxkc3(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.atxeb3 = response.data;
    },
    async fetchAtxkc4(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.atxeb4 = response.data;
    },
    async fetchAtxkc5(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.atxeb5 = response.data;
    },
    async fetchAtxkc6(category, limit) {
      const response = await axiosClient.get(`artikel/category/` + category + `/` + limit);
      this.atxeb6 = response.data;
    }
  },
});