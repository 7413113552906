<template>
    <section>
        <div class="section-header">
            <span class="section-label">Kecelakaan</span>
        </div>
        <div class="section-content">
            <div class="sc-inner">
                <div class="sci-left">
                    <div class="sl-detail" v-for="fa in this.firstAidDetails" :key="fa.id">
                        <div class="sl-head">
                            <h1 class="main-title">{{ fa.title }}</h1>
                            <p class="p-author">
                                <span class="pa-param">Co-authored by</span><span class="pa-value">Fuad Hasan</span>
                            </p>
                        </div>
                        <div class="sl-body">
                            <div class="sl-content">
                                <p class="sc-description">{{ fa.description }}</p>
                                <div class="sc-part" v-for="step in fa.steps" :key="step.id">
                                    <div class="sc-part-head">
                                        <div class="sph-num">
                                            <span>{{ step.step_num }}</span>
                                        </div>
                                        <div class="sph-text">
                                            <p>Method {{ step.step_num }}</p>
                                            <h2>{{ step.title }}</h2>
                                        </div>
                                    </div>
                                    <div class="sc-part-body">
                                        <div class="spb-img">
                                            <img v-bind:src="this.imgsrc + step.picture" alt="p3k melinda" />
                                        </div>
                                        <div class="spb-description">
                                            <p>{{ step.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sc-more">
                            <router-link to="/">Load more detail</router-link>
                        </div>
                    </div>
                </div>
                <div class="sci-right">
                    <div class="sr-author">
                        <div class="sra-head">
                            <div class="sra-icon">
                                <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda">
                            </div>
                            <div class="sra-text">
                                <h2>John Doe</h2>
                                <p>Technology Writer, The Atlantic</p>
                            </div>
                        </div>
                        <div class="sra-cu">
                            <div class="sra-part sra-part-cr">
                                <p class="sra-part-param">Created :</p>
                                <p class="sra-part-value">May 6, 2021</p>
                            </div>
                            <div class="sra-part sra-part-up">
                                <p class="sra-part-param">Updated :</p>
                                <p class="sra-part-value">May 6, 2021</p>
                            </div>
                        </div>
                    </div>
                    <div class="sr-reference">
                        <h2>References</h2>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Andrea Rudominer, MD, MPH. Board Certified Pediatrician & Integrative Medicine Doctor. Expert Interview. 6 May 2020.</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Andrea Rudominer, MD, MPH. Board Certified Pediatrician & Integrative Medicine Doctor. Expert Interview. 6 May 2020.</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="sr-related">
                        <h2>Related Categories</h2>
                        <router-link to="/">
                            <div class="sr-related-tag">
                                <div class="srt-text">
                                    <span>Pemeriksaan</span>
                                </div>
                                <div class="srt-num">
                                    <span>21</span>
                                </div>
                            </div>
                        </router-link>
                        <router-link to="/">
                            <div class="sr-related-tag">
                                <div class="srt-text">
                                    <span>Penanganan Pertama</span>
                                </div>
                                <div class="srt-num">
                                    <span>17</span>
                                </div>
                            </div>
                        </router-link>
                        <router-link to="/">
                            <div class="sr-related-tag">
                                <div class="srt-text">
                                    <span>Proteksi</span>
                                </div>
                                <div class="srt-num">
                                    <span>17</span>
                                </div>
                            </div>
                        </router-link>
                        <router-link to="/">
                            <div class="sr-related-tag">
                                <div class="srt-text">
                                    <span>Tips P3K</span>
                                </div>
                                <div class="srt-num">
                                    <span>17</span>
                                </div>
                            </div>
                        </router-link>
                        <router-link to="/">
                            <div class="sr-related-tag">
                                <div class="srt-text">
                                    <span>Kecelakaan</span>
                                </div>
                                <div class="srt-num">
                                    <span>17</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="sr-tags">
                        <h2>Tags</h2>
                        <div class="srt-inner">
                            <div class="srt-part">
                                <router-link to="">CoronaVac</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">WHO</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">Technology</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">JournalMedic</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">Pfizer</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">SWAB</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">BPJS</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">Asuransi</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useFirstAidStore } from "../../stores/firstaid";
    export default {
        name: 'FirstAidContent',
        data() {
            return {
                firstAidDetails: null,
                imgsrc: process.env.VUE_APP_ROOT_API + 'images/first-aids/steps/',
                id: this.$route.params.id
            }
        },
        methods: {
            setFirstAid(){
                const store = useFirstAidStore();
                store.fetchFirstAidDetails(this.id);
                this.firstAidDetails = computed(() => store.firstAidDetails);
            }
        },
        mounted() {
            this.setFirstAid()
        },
        setup() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #FEF8F0;
    }
    .section-header{
        padding-top: 5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .section-label{
        padding: 0.5rem;
        color: #1B4674;
        background: #FEDF87;
        font-family: poppins;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 5px;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        color: #1B4674;
        line-height: 1.1;
        margin-top: 1%;
        margin-bottom: 1%;
        transition: 0.2s;
    }
    .p-author{
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
        transition: 0.2s;
    }
    .pa-param{
        color: #575757;
        font-size: 0.875rem;
        font-weight: 400;
        padding-right: 1rem;
    }
    .pa-value{
        color: #75C6EF;
        font-size: 0.875rem;
        font-weight: 700;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        margin-top: 2rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .sci-left{
        width: 60%;
        color: #575757;
        transition: 0.2s;
    }
    .sl-body{
        padding-top: 0.5rem;
    }
    .sl-content{
        font-size: 1.2rem;
        text-align: justify;
    }
    .sc-part{
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        background: #ffffff;
    }
    .sc-part-head{
        display: flex;
        flex-wrap: nowrap;
        padding: 2rem;
    }
    .sph-num{
        min-width: 100px;
        width: 6.25rem;
        min-height: 100px;
        height: 6.25rem;
        display: flex;
        justify-content: center;
        font-family: poppins;
        font-size: 1.5rem;
        font-weight: 700;
        background: #EA476A;
        color: #ffffff;
        border-radius: 100px;
        padding-top: 1.85rem;
    }
    .sph-text{
        padding-left: 2rem;
    }
    .sph-text p{
        font-size: 1.5rem;
        font-weight: 400;
        margin-top: 0.5rem;
    }
    .sph-text h2{
        color: #1B4674;
        font-family: poppins;
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 0.5rem;
    }
    .sc-part-body{
        width: 100%;
    }
    .spb-img{
        width: 100%;
    }
    .spb-img img{
        width: 100%;
    }
    .spb-description{
        padding: 2rem;
    }
    .sc-more{
        margin-top: 1.6875rem;
        text-align: center;
    }
    .sc-more a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    .sci-right{
        width: 40%;
        padding-left: 5rem;
        transition: 0.2s;
    }
    .sr-author{
        background: #ffffff;
        width: 100%;
        padding: 2rem;
        margin-bottom: 2rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
        border-radius: 5px;
    }
    .sra-head{
        display: flex;
        flex-wrap: nowrap;
    }
    .sra-icon{
        min-width: 50px;
        width: 3.125rem;
        min-height: 50px;
        height: 3.125rem;
    }
    .sra-icon img{
        width: 100%;
        height: 100%;
        background: orange;
        border-radius: 3.125rem;
    }
    .sra-text{
        padding-left: 1rem;
    }
    .sra-text h2{
        font-size: 1.25rem;
    }
    .sra-text p{
        margin-top: 0.5rem;
    }
    .sra-cu{
        display: flex;
        margin-top: 2rem;
        color: #575757;
    }
    .sra-part{
        width: 50%;
    }
    .sra-part-cr{
        border-right: 1px solid #E4E4E4;
    }
    .sra-part-value{
        font-weight: 700;
        margin-top: 0.5rem;
    }
    .sra-part-up{
        padding-left: 2rem;
    }
    .sr-reference{
        background: #ffffff;
        padding: 2rem;
        width: 100%;
    }
    .sr-reference h2{
        margin-bottom: 0.5rem;
    }
    .sr-reference a{
        color: #575757;
        text-decoration: none;
    }
    .hc-list{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 0.5rem;
        padding-top: 0.5rem;
    }
    .hcl-icon{
        min-width: 10px;
        width: 0.5rem;
        padding-top: 0.1rem;
    }
    .hcl-icon img{
        width: 100%;
    }
    .hcl-text{
        width: 85%;
        padding-left: 1rem;
    }
    .sr-related{
        margin-top: 2rem;
        margin-bottom: 3rem;
        width: 100%;
        transition: 0.2s;
    }
    .sr-related a{
        color: #575757;
        text-decoration: none;
    }
    .sr-related h2{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: #1B4674;
        margin-bottom: 1rem;
    }
    .sr-related-tag{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 1rem;
    }
    .srt-text{
        padding-right: 0.5rem;
        font-family: poppins;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1;
        color: #575757;
    }
    .srt-num{
        min-width: 16px;
        width: 1rem;
        min-height: 16px;
        height: 1rem;
        font-size: 0.5rem;
        border: 1px solid #F2CB9C;
        border-radius: 1rem;
        text-align: center;
        padding: 0.1rem;
    }
    .sr-tags{
        width: 100%;
        transition: 0.2s;
    }
    .srt-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;
    }
    .srt-part{
        background: #FCE8CE;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0.5rem;
        margin-left: 0;
        border-radius: 50px;
    }
    .srt-part a{
        color: #575757;
        text-decoration: none;
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-top: 0;
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .sci-left{
            width: 100%;
        }
        .main-title{
            font-size: 2.6rem;
        }
        .p-author{
            width: 100%;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-left: 0;
            margin-top: 2rem;
        }
        .sr-related{
            width: 50%;
        }
        .sr-tags{
            width: 50%;
        }
    }
    @media only screen and (max-width: 710px){
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sr-related{
            width: 100%;
        }
        .sr-tags{
            width: 100%;
        }
    }
</style>