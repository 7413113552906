<template>
    <div class="content">
        <ArticleList />
        <ArticleLearning />
    </div>
</template>

<script setup>
    import ArticleList from '@/components/article/ArticleList.vue'
    import ArticleLearning from '@/components/article/ArticleLearning.vue'
    document.title = 'Articles - P3K Melinda Hospital';
</script>