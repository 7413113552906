<template>
    <section>
        <div class="section-content">
            <div class="sc-inner">
                <div class="sci-left">
                    <p class="sm-title">Melinda Hospital P3K</p>
                    <h2>Trusted Research and Expert Knowledge.</h2>
                    <p class="sci-left-description">We work with credentialed experts, a team of trained researchers, and a devoted community to create the most reliable, comprehensive and delightful how-to content on the Internet.</p>
                </div>
                <div class="sci-right">
                    <div class="scr-part">
                        <div class="scr-part-icon">
                            <img src="../../assets/images/icons/imght1.png" alt="p3k melinda">
                        </div>
                        <div class="scr-part-text">
                            <h3>Authoritative</h3>
                            <p>90,000 academically researched articles</p>
                        </div>
                    </div>
                    <div class="scr-part">
                        <div class="scr-part-icon">
                            <img src="../../assets/images/icons/imght2.png" alt="p3k melinda">
                        </div>
                        <div class="scr-part-text">
                            <h3>Trustworthy</h3>
                            <p>1000+ expert co-authors across 50,000 articles</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'AboutTrusted',
        methods: {
            // 
        }
    }
</script>

<style scoped>
    section{
        width: 100%;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #FEF8F0;
    }
    .section-header{
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
        transition: 0.2s;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .section-content{
        width: 100%;
        height: 100%;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .sci-left{
        width: 50%;
        color: #575757;
        transition: 0.2s;
    }
    .sci-left h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        color: #1B4674;
        transition: 0.2s;
    }
    .sci-left-description{
        margin-top: 1rem;
    }
    .sci-right{
        width: 50%;
        transition: 0.2s;
    }
    .scr-part{
        display: flex;
        flex-wrap: nowrap;
        padding: 1rem;
        margin-bottom: 2rem;
        transition: 0.2s;
    }
    .scr-part-icon{
        width: 19%;
        text-align: center;
        transition: 0.2s;
    }
    .scr-part-text{
        width: 81%;
        padding-left: 1rem;
        transition: 0.2s;
    }
    .scr-part-text h3{
        margin: 0;
    }
    .scr-part-text p{
        margin-top: 1rem;
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sc-inner{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .sci-left h2{
            font-size: 2rem;
        }
        
    }
    @media only screen and (max-width: 710px){
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sc-inner{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-left{
            width: 100%;
        }
        .sci-right{
            width: 100%;
        }
        .scr-part{
            padding-left: 0;
        }
        .scr-part-icon{
            text-align: left;
        }
        .scr-part-icon img{
            /* width: 90%; */
        }
        .scr-part-text{
            /* padding-left: 0; */
        }
    }
</style>