<template>
    <section>
        <div class="section-header">
            <h2>Meet The P3K Editor Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="section-content">
            <div class="sc-inner">
                <div class="sci-part">
                    <div class="sp-inner" @mouseenter="spEnter('spc-1')" @mouseleave="spLeave('spc-1')">
                        <img src="../../assets/images/picture/imgat5.png" alt="p3k melinda" />
                        <div class="spi-caption">
                            <router-link to="" >
                                <h3>Christine Abigail</h3>
                            </router-link>
                            <p>CEO Food Traveller</p>
                        </div>
                        <div class="sp-cover" id="spc-1">
                            <div class="spc-inner"></div>
                        </div>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sp-inner" @mouseenter="spEnter('spc-2')" @mouseleave="spLeave('spc-2')">
                        <img src="../../assets/images/picture/imgat5.png" alt="p3k melinda" />
                        <div class="spi-caption">
                            <router-link to="" >
                                <h3>Scoyt Alkin</h3>
                            </router-link>
                            <p>CTO Food Traveller</p>
                        </div>
                        <div class="sp-cover" id="spc-2">
                            <div class="spc-inner"></div>
                        </div>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sp-inner" @mouseenter="spEnter('spc-3')" @mouseleave="spLeave('spc-3')">
                        <img src="../../assets/images/picture/imgat5.png" alt="p3k melinda" />
                        <div class="spi-caption">
                            <router-link to="" >
                                <h3>David SImanjuntak</h3>
                            </router-link>
                            <p>COO Food Traveller</p>
                        </div>
                        <div class="sp-cover" id="spc-3">
                            <div class="spc-inner"></div>
                        </div>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sp-inner" @mouseenter="spEnter('spc-4')" @mouseleave="spLeave('spc-4')">
                        <img src="../../assets/images/picture/imgat5.png" alt="p3k melinda" />
                        <div class="spi-caption">
                            <router-link to="" >
                                <h3>Marina Haque</h3>
                            </router-link>
                            <p>Head of Finance</p>
                        </div>
                        <div class="sp-cover" id="spc-4">
                            <div class="spc-inner"></div>
                        </div>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sp-inner" @mouseenter="spEnter('spc-5')" @mouseleave="spLeave('spc-5')">
                        <img src="../../assets/images/picture/imgat5.png" alt="p3k melinda" />
                        <div class="spi-caption">
                            <router-link to="" >
                                <h3>Devina Atmaja</h3>
                            </router-link>
                            <p>Head of Marketing</p>
                        </div>
                        <div class="sp-cover" id="spc-5">
                            <div class="spc-inner"></div>
                        </div>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sp-inner" @mouseenter="spEnter('spc-6')" @mouseleave="spLeave('spc-6')">
                        <img src="../../assets/images/picture/imgat5.png" alt="p3k melinda" />
                        <div class="spi-caption">
                            <router-link to="" >
                                <h3>Dave Gultom</h3>
                            </router-link>
                            <p>Head of Mobile Developer</p>
                        </div>
                        <div class="sp-cover" id="spc-6">
                            <div class="spc-inner"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutTeam',
    methods: {
        spEnter(a){
            document.getElementById(a).style.top = '-100%';
            document.getElementById(a).children[0].style.opacity = '0';
        },
        spLeave(a){
            document.getElementById(a).style.top = '0';
            document.getElementById(a).children[0].style.opacity = '0.75';
        }
    }
}
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 36rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #ffffff;
        background: #1B4674;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
    }
    .section-header{
        position: relative;
        padding-left: 26%;
        padding-right: 26%;
        padding-bottom: 2.5rem;
        text-align: center;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
        transition: 0.2s;
    }
    .sci-part{
        position: relative;
        color: #1C4677;
        width: 33.33%;
        min-width: 19rem;
        padding: 1%;
        transition: 0.2s;
    }
    .sp-inner{
        position: relative;
        border-radius: 5px;
        overflow: hidden;
    }
    .sp-inner img{
        width: 100%;
        height: 100%;
    }
    .sp-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* transition: 0.2s; */
        z-index: 1;
    }
    .spc-inner{
        background: grey;
        width: 100%;
        height: 99.2%;
        border-radius: 5px;
        opacity: 0.75;
        transition: 0.2s;
    }
    .spi-caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: #ffffff;
        padding: 5%;
        z-index: 5;
    }
    .spi-caption a{
        text-decoration: none;
        color: #ffffff;
    }
    .spi-caption h3{
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1.125rem;
        font-weight: 700;
        font-family: poppins;
    }
    .spi-caption p{
        margin: 0;
        font-weight: 400;
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sc-inner{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .sci-part{
            width: 50%;
            min-width: 11rem;
        }
    }
    @media only screen and (max-width: 685px){
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sc-inner{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-part{
            width: 100%;
        }
    }
</style>