<template>
    <section>
        <div class="section-header">
            <p class="sm-title">Melinda Hospital P3K</p>
            <h2>Browse by Category</h2>
            <div class="sh-more">
                <img src="../../assets/images/icons/cvright.png" alt="p3k melinda">
            </div>
        </div>
        <div class="section-tab">
            <div class="ths-button float-button-mobile" @click="thsOption">
                <div class="ths-button-text float-button-mobile" id="hbtbt">Tips Pertolongan Pertama</div>
                <div class="ths-button-icon float-button-mobile">
                    <img class="tbi-img float-button-mobile" id="tbi-img" src="../../assets/images/icons/arrow-1.png" alt="p3k melinda">
                </div>
            </div>
            <div class="tab-head mobile-noflex float-mobile" id="tab-head">
                <div class="cov-tab-button" v-for="(category, index) in articleCategories" :key="category.id" @click="ctbClick(category.name)">
                    <div class="tab-button tb-active" :id="'button-' + category.tab_id" @click="selectTab(category.tab_id, 'button-' + category.tab_id)" v-if="index == 0">
                        <div class="tb-text">{{ category.name }}</div>
                        <div class="tb-num">{{ category.count }}</div>
                    </div>
                    <div class="tab-button" :id="'button-' + category.tab_id" @click="selectTab(category.tab_id, 'button-' + category.tab_id)" v-else>
                        <div class="tb-text">{{ category.name }}</div>
                        <div class="tb-num">{{ category.count }}</div>
                    </div>
                </div>
            </div>
            <div class="tab-body">
                <div class="tab-content tc-first" id="tab-1">
                    <div class="tc-inner" id="tc-articles-hb1">
                        <div class="tc-part tc-part-hb1" v-for="article in articlesHb1" :key="article.id" :count="article.count">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb1('tc-articles-hb1', 'tc-part-hb1')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-2">
                    <div class="tc-inner" id="tc-articles-hb2">
                        <div class="tc-part tc-part-hb2" v-for="article in articlesHb2" :key="article.id" :count="article.count">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb2('tc-articles-hb2', 'tc-part-hb2')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-3">
                    <div class="tc-inner" id="tc-articles-hb3">
                        <div class="tc-part" v-for="article in articlesHb3" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb3('tc-articles-hb3')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-4">
                    <div class="tc-inner" id="tc-articles-hb4">
                        <div class="tc-part" v-for="article in articlesHb4" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb4('tc-articles-hb4')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-5">
                    <div class="tc-inner" id="tc-articles-hb5">
                        <div class="tc-part" v-for="article in articlesHb5" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb5('tc-articles-hb5')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-6">
                    <div class="tc-inner" id="tc-articles-hb6">
                        <div class="tc-part" v-for="article in articlesHb6" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb6('tc-articles-hb6')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-7">
                    <div class="tc-inner" id="tc-articles-hb7">
                        <div class="tc-part" v-for="article in articlesHb7" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb7('tc-articles-hb7')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-8">
                    <div class="tc-inner" id="tc-articles-hb8">
                        <div class="tc-part" v-for="article in articlesHb8" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb7('tc-articles-hb8')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-9">
                    <div class="tc-inner" id="tc-articles-hb9">
                        <div class="tc-part" v-for="article in articlesHb9" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb7('tc-articles-hb9')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-10">
                    <div class="tc-inner" id="tc-articles-hb10">
                        <div class="tc-part" v-for="article in articlesHb10" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb7('tc-articles-hb10')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-11">
                    <div class="tc-inner" id="tc-articles-hb11">
                        <div class="tc-part" v-for="article in articlesHb11" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb7('tc-articles-hb11')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-12">
                    <div class="tc-inner" id="tc-articles-hb12">
                        <div class="tc-part" v-for="article in articlesHb12" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb7('tc-articles-hb12')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-13">
                    <div class="tc-inner" id="tc-articles-hb13">
                        <div class="tc-part" v-for="article in articlesHb13" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb7('tc-articles-hb13')">View more articles</router-link>
                    </div>
                </div>
                <div class="tab-content" id="tab-14">
                    <div class="tc-inner" id="tc-articles-hb14">
                        <div class="tc-part" v-for="article in articlesHb14" :key="article.id">
                            <div class="tcp-img">
                                <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                <div class="sci-gradient"></div>
                            </div>
                            <div class="sci-label">
                                <span>{{ article.name }}</span>
                            </div>
                            <div class="sci-caption">
                                <div class="sci-caption-inner">
                                    <router-link :to="detailpath + article.id" >
                                        <h3>{{ article.title }}</h3>
                                    </router-link>
                                    <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sc-browse">
                        <router-link to="" @click="loadMoreHb7('tc-articles-hb14')">View more articles</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    const store = useArticleStore();
    const articleCategories = computed(() => store.articleCategories);
    const articlesHb1 = computed(() => store.articlesHb1);
    const articlesHb2 = computed(() => store.articlesHb2);
    const articlesHb3 = computed(() => store.articlesHb3);
    const articlesHb4 = computed(() => store.articlesHb4);
    const articlesHb5 = computed(() => store.articlesHb5);
    const articlesHb6 = computed(() => store.articlesHb6);
    const articlesHb7 = computed(() => store.articlesHb7);
    const articlesHb8 = computed(() => store.articlesHb8);
    const articlesHb9 = computed(() => store.articlesHb9);
    const articlesHb10 = computed(() => store.articlesHb10);
    const articlesHb11 = computed(() => store.articlesHb11);
    const articlesHb12 = computed(() => store.articlesHb12);
    const articlesHb13 = computed(() => store.articlesHb13);
    const articlesHb14 = computed(() => store.articlesHb14);
    store.fetchArticleCategories();
    store.fetchArticlesHb1(1, 8);
    store.fetchArticlesHb2(2, 8);
    store.fetchArticlesHb3(3, 8);
    store.fetchArticlesHb4(4, 8);
    store.fetchArticlesHb5(5, 8);
    store.fetchArticlesHb6(6, 8);
    store.fetchArticlesHb7(7, 8);
    store.fetchArticlesHb8(8, 8);
    store.fetchArticlesHb9(9, 8);
    store.fetchArticlesHb10(10, 8);
    store.fetchArticlesHb11(11, 8);
    store.fetchArticlesHb12(12, 8);
    store.fetchArticlesHb13(13, 8);
    store.fetchArticlesHb14(14, 8);
    function thsOption(){
        if(document.getElementById('tab-head').style.display == 'flex'){
            document.getElementById('tab-head').style.display = 'none';
            document.getElementById('tbi-img').style.transform = 'rotate(0)';
        }else{
            document.getElementById('tab-head').style.display = 'flex';
            document.getElementById('tbi-img').style.transform = 'rotate(540deg)';
        }
    }
    function ctbClick(a){
        document.getElementById('hbtbt').innerHTML = a;
    }
    function selectTab(a, b){
        var tb = document.getElementsByClassName('tab-button');
        var tc = document.getElementsByClassName('tab-content');
        var i;
        for(i = 0; i < tc.length; i++){
            tc[i].style.display = 'none';
            tb[i].classList.remove('tb-active');
        }
        document.getElementById(a).style.display = 'block';
        document.getElementById(b).classList.add('tb-active');
    }
    function epochDMY(ts){
        const months = ["Januari", "Pebruari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        var dt = new Date(ts);
        var d = dt.getDay();
        var m = months[dt.getMonth()];
        var y = dt.getFullYear();
        return d + ' ' + m +' ' + y;
    }
    function loadMoreHb1(id, cl){
        const cn = document.getElementsByClassName(cl);
        const al = cn[0].getAttribute('count');
        if(cn > al){
            const plus = 4;
            store.fetchArticlesHb1(1, document.getElementById(id).children.length + plus);
        }else{
            console.log('semua artikel telah ditampilkan');
        }
    }
    function loadMoreHb2(id, cl){
        const cn = document.getElementsByClassName(cl);
        const al = cn[0].getAttribute('count');
        if(document.getElementById(id).children.length < al){
            const plus = 4;
            store.fetchArticlesHb2(2, document.getElementById(id).children.length + plus);
        }else{
            console.log('semua artikel telah ditampilkan');
        }
    }
    function loadMoreHb3(id){
        const plus = 4;
        store.fetchArticlesHb3(3, document.getElementById(id).children.length + plus);
    }
    function loadMoreHb4(id){
        const plus = 4;
        store.fetchArticlesHb4(4, document.getElementById(id).children.length + plus);
    }
    function loadMoreHb5(id){
        const plus = 4;
        store.fetchArticlesHb5(5, document.getElementById(id).children.length + plus);
    }
    function loadMoreHb6(id){
        const plus = 4;
        store.fetchArticlesHb6(6, document.getElementById(id).children.length + plus);
    }
    function loadMoreHb7(id){
        const plus = 4;
        store.fetchArticlesHb7(7, document.getElementById(id).children.length + plus);
    }
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/articles/';
    const detailpath = '/article/detail/';
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 36rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
    }
    section p{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1.5rem;
    }
    .section-header{
        position: relative;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .sh-more{
        position: absolute;
        right: 12.25rem;
        bottom: 0;
    }
    .section-tab{
        position: relative;
        width: 100%;
        z-index: 10;
    }
    .ths-button{
        display: none;
    }
    .tab-head{
        display: flex;
        flex-wrap: wrap;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
    }
    .tab-head::-webkit-scrollbar{
        display: none;
    }
    .tab-button{
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: center;
        cursor: pointer;
        margin-top: 0.75rem;
        margin-right: 0.75rem;
        font-family: poppins;
        font-size: 1rem;
        font-weight: 600;
        color: #575757;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        user-select: none;
        line-height: 1;
    }
    .tb-num{
        display: flex;
        justify-content: center;
        font-size: 0.5rem;
        padding: 0.36rem;
        width: 1.2rem;
        height: 1.2rem;
        border: 1px solid #F2CB9C;
        border-radius: 1rem;
        margin-left: 0.5rem;
    }
    .tb-active{
        color: #1B4674;
        background: #FEDF87;
        border-radius: 5px;
    }
    .tb-active .tb-num{
        background: #EA476A;
        color: #ffffff;
        border: 1px solid #EA476A;
    }
    .tab-body{
        width: 100%;
        min-height: 10rem;
    }
    .tab-content{
        display: none;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 2rem;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
    }
    .tc-first{
        display: flex;
    }
    .tc-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .tc-inner::-webkit-scrollbar{
        display: none;
    }
    .tc-part{
        position: relative;
        color: #ffffff;
        width: 25%;
        min-width: 19rem;
        min-height: 22rem;
        padding: 1%;
        transition: 0.2s;
    }
    .tcp-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .tcp-img img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-label{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 3rem;
        font-family: poppins;
        font-size: 1rem;
        white-space: nowrap;
    }
    .sci-label span{
        background: #75C6EF;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
    }
    .sci-label-sp span{
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 5px;
    }
    .sci-caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        padding-top: 1.5rem;
    }
    .sci-caption-inner{
        padding: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-caption a{
        text-decoration: none;
        color: #ffffff;
    }
    .sci-caption h3{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1.2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sci-caption-date{
        margin: 0;
        margin-top: 0.5rem;
    }
    .sc-browse{
        margin-top: 1.6875rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .sc-browse a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1585px){
        .tc-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sh-more{
            right: 5%;
            display: none;
        }
        .tab-head{
            padding-left: 0;
            padding-right: 0;
            margin-left: 5%;
            margin-right: 5%;
        }
        .tab-content{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .tab-head{
            flex-wrap: nowrap;
            overflow: auto;
        }
        .tc-part{
            width: 50%;
        }
    }
    @media only screen and (max-width: 685px){
        section{
            padding-top: 0;
        }
        section h2{
            font-size: 2rem;
        }
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
            padding-bottom: 1rem;
        }
        .sh-more{
            right: 2.5%;
        }
        .tab-head{
            margin-left: 2.5%;
            margin-right: 2.5%;
        }
        .tab-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .ths-button{
            display: flex;
            width: 95%;
            background: #ffffff;
            padding: 1rem;
            border-radius: 5px;
            box-shadow: 0 4px 8px 0 whitesmoke, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            cursor: pointer;
            font-family: poppins;
            font-size: 1rem;
            font-weight: 600;
            color: #575757;
            margin-left: 2.5%;
        }
        .ths-button-text{
            width: 90%;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .ths-button-icon{
            width: 10%;
            text-align: right;
        }
        .tbi-img{
            transition: 0.5s;
        }
        .tab-head{
            display: none;
            position: absolute;
            top: 4.1rem;
            left: 0;
            width: 95%;
            flex-wrap: wrap;
            z-index: 1;
            background: #ffffff;
            border-radius: 5px;
            box-shadow: 0 4px 8px 0 whitesmoke, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
        .cov-tab-button{
            width: 100%;
        }
        .tab-button{
            justify-content: left;
        }
        .tb-active{
            color: #575757;
            background: none;
            border-radius: 0px;
        }
        .tb-active .tb-num{
            background: none;
            color: #575757;
            border: 1px solid #F2CB9C;
        }
        .tab-content{
            padding-left: 0;
            padding-right: 0;
        }
        .tc-inner{
            flex-wrap: nowrap;
            overflow: auto;
            padding-left: 1.5%;
            padding-right: 1.5%;
        }
        .tc-part{
            min-width: 22rem;
            width: 33.33%;
        }
        .sci-label{
            padding-top: 2rem;
            padding-left: 1.5rem;
        }
    }
</style>