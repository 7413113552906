<template>
    <div class="content">
        <HomeHeader />
        <HomeFeatured />
        <HomeTrusted />
        <HomeBrowse />
        <HomeLearning />
    </div>
</template>

<script setup>
    import HomeHeader from '@/components/home/HomeHeader.vue'
    import HomeFeatured from '@/components/home/HomeFeatured.vue'
    import HomeTrusted from '@/components/home/HomeTrusted.vue'
    import HomeBrowse from '@/components/home/HomeBrowse.vue'
    import HomeLearning from '@/components/home/HomeLearning.vue'
    document.title = 'P3K Melinda Hospital';
</script>