<template>
    <div class="header">
        <div class="hh-inner">
            <div class="hh-part hh-left">
                <div class="hh-left-inner animate__animated animate__backInLeft animate__delay-0.2s">
                    <p class="p-1">Melinda Hospital P3K Explore</p>
                    <h1>{{ activeCategory }}</h1>
                </div>
            </div>
            <!-- <div class="hh-part hh-right">
            </div> -->
        </div>
    </div>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useFirstAidStore } from "../../stores/firstaid";
    export default {
        name: 'ExploreCategory',
        data(){
            return {
                activeCategory: computed(() => useFirstAidStore().activeCategory),
                id: this.$route.params.id
            }
        },
        methods: {
            setExploreCategory(){
                useFirstAidStore().fetchFabc(this.id, 15); // (category, limit) =====
                useFirstAidStore().fetchArticleCategories();
                this.firstAids = computed(() => useFirstAidStore().firstAidsByCategory);
            }
        },
        mounted() {
            this.setExploreCategory();
        }
    }
</script>

<style scoped>
    .header{
        position: relative;
        width: 100%;
        height: 22rem;
        padding-top: 7.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        background: #1B4674;
        transition: 0.2s;
    }
    .hh-inner{
        display: flex;
        width: 100%;
        height: 100%;
    }
    .hh-part{
        width: 50%;
        height: 100%;
    }
    .hh-left{
        color: #ffffff;
        /* padding-right: 6.69rem; */
        padding-bottom: 0;
    }
    .p-1{
        font-weight: 600;
        color: #75C6EF;
    }
    .hh-left h1{
        font-family: poppins;
        font-size: 3.25rem;
        /* width: 22.56rem; */
        margin-top: 1.37rem;
    }
    @media only screen and (max-width: 1410px){
        .header{
            padding-top: 2rem;
            padding-left: 5%;
            padding-right: 5%;
            padding-bottom: 2rem;
            height: auto;
        }
        .hh-left{
            width: 100%;
        }
    }
    @media only screen and (max-width: 1050px){
        .hh-left h1{
            font-size: 2.6rem;
            width: auto;
        }
    }
    @media only screen and (max-width: 685px){
        .header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .hh-left h1{
            font-size: 2rem;
        }
    }
</style>