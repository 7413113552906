<template>
    <section>
        <div class="section-head">
            <div class="tab-head" id="tab-head">
                <div class="th-select">
                    <p class="ths-title">Choose Categories</p>
                    <div class="ths-button float-button" @click="thsOption">
                        <div class="ths-button-text float-button">Tips Pertolongan Pertama</div>
                        <div class="ths-button-icon float-button">
                            <img class="tbi-img float-button" id="tbi-img" src="../../assets/images/icons/arrow-1.png" alt="p3k melinda">
                        </div>
                    </div>
                    <div class="ths-option float" id="ths-option">
                        <div class="ths-option-list" v-for="category in articleCategories" :key="category.id">
                            <router-link :to="'/explore/category/' + category.id" >{{ category.name }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-body">
            <div class="section-part">
                <div class="section-part-side" v-for="(fa, index) in exploreData" :key="fa.id">
                    <div class="sps-middle sm-left" v-if="index == 2">
                        <div class="section-header">
                            <p class="sm-title">Explore</p>
                            <h2>{{ fa.name }}</h2>
                        </div>
                        <div class="section-content">
                            <div class="sc-inner" id="sc-atxeb1">
                                <div class="sci-part" v-for="article in fa.data" :key="article.id">
                                    <div class="tcp-img">
                                        <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                        <div class="sci-gradient"></div>
                                    </div>
                                    <div class="sci-label">
                                        <span>{{ article.category_name }}</span>
                                    </div>
                                    <div class="sci-caption">
                                        <div class="sci-caption-inner">
                                            <router-link :to="detailpath + article.id" >
                                                <h3>{{ article.title }}</h3>
                                            </router-link>
                                            <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sc-browse">
                                <router-link :to="'/explore/category/' + fa.id">Browse featured article</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="sps-middle sm-right" v-else-if="index == 3">
                        <div class="section-header">
                            <p class="sm-title">Explore</p>
                            <h2>{{ fa.name }}</h2>
                        </div>
                        <div class="section-content">
                            <div class="sc-inner" id="sc-atxeb1">
                                <div class="sci-part" v-for="article in fa.data" :key="article.id">
                                    <div class="tcp-img">
                                        <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                        <div class="sci-gradient"></div>
                                    </div>
                                    <div class="sci-label">
                                        <span>{{ article.category_name }}</span>
                                    </div>
                                    <div class="sci-caption">
                                        <div class="sci-caption-inner">
                                            <router-link :to="detailpath + article.id" >
                                                <h3>{{ article.title }}</h3>
                                            </router-link>
                                            <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sc-browse">
                                <router-link :to="'/explore/category/' + fa.id">Browse featured article</router-link>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="sm-left" v-if="index % 2 == 0">
                            <div class="section-header">
                                <p class="sm-title">Explore</p>
                                <h2>{{ fa.name }}</h2>
                            </div>
                            <div class="section-content">
                                <div class="sc-inner" id="sc-atxeb1">
                                    <div class="sci-part" v-for="article in fa.data" :key="article.id">
                                        <div class="tcp-img">
                                            <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                            <div class="sci-gradient"></div>
                                        </div>
                                        <div class="sci-label">
                                            <span>{{ article.category_name }}</span>
                                        </div>
                                        <div class="sci-caption">
                                            <div class="sci-caption-inner">
                                                <router-link :to="detailpath + article.id" >
                                                    <h3>{{ article.title }}</h3>
                                                </router-link>
                                                <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sc-browse">
                                    <router-link :to="'/explore/category/' + fa.id">Browse featured article</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="sm-right" v-else>
                            <div class="section-header">
                                <p class="sm-title">Explore</p>
                                <h2>{{ fa.name }}</h2>
                            </div>
                            <div class="section-content">
                                <div class="sc-inner" id="sc-atxeb1">
                                    <div class="sci-part" v-for="article in fa.data" :key="article.id">
                                        <div class="tcp-img">
                                            <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                                            <div class="sci-gradient"></div>
                                        </div>
                                        <div class="sci-label">
                                            <span>{{ article.category_name }}</span>
                                        </div>
                                        <div class="sci-caption">
                                            <div class="sci-caption-inner">
                                                <router-link :to="detailpath + article.id" >
                                                    <h3>{{ article.title }}</h3>
                                                </router-link>
                                                <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sc-browse">
                                    <router-link :to="'/explore/category/' + fa.id">Browse featured article</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useFirstAidStore } from "../../stores/firstaid";
    const store = useFirstAidStore();
    const articleCategories = computed(() => store.articleCategories);
    const exploreData = computed(() => store.exploreData);
    store.fetchArticleCategories();
    store.fetchExploreData(6);
    function thsOption(){
        if(document.getElementById('ths-option').style.display == 'block'){
            document.getElementById('ths-option').style.display = 'none';
            document.getElementById('tbi-img').style.transform = 'rotate(0)';
        }else{
            document.getElementById('ths-option').style.display = 'block';
            document.getElementById('tbi-img').style.transform = 'rotate(541deg)';
        }
    }
    function epochDMY(ts){
        const months = ["Januari", "Pebruari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        var dt = new Date(ts);
        var d = dt.getDay();
        var m = months[dt.getMonth()];
        var y = dt.getFullYear();
        return d + ' ' + m +' ' + y;
    }
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/first-aids/steps/';
    const detailpath = '/explore/detail/';
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 36rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
    }
    section h2{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        margin: 0;
        padding-left: 0.5rem;
    }
    section p{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1.5rem;
        padding-left: 0.5rem;
    }
    .tab-head{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-top: 5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .th-select{
        position: absolute;
        top: 0;
        left: 11.75rem;
        height: 4.1rem;
        width: 40%;
        user-select: none;
        transition: 0.2s;
    }
    .ths-title{
        font-size: 1rem;
        color: #575757;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .ths-button{
        display: flex;
        background: #ffffff;
        padding: 1rem;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 whitesmoke, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        cursor: pointer;
        color: #575757;
    }
    .ths-button-text{
        width: 90%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ths-button-icon{
        width: 10%;
        text-align: right;
    }
    .tbi-img{
        transition: 0.5s;
    }
    .ths-option{
        position: absolute;
        top: 136%;
        left: 0;
        width: 100%;
        background: #ffffff;
        z-index: 1;
        border: 1px solid whitesmoke;
        border-top: none;
        border-radius: 5px;
        display: none;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 whitesmoke, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .ths-option-list{
        cursor: pointer;
        transition: 0.2s;
    }
    .ths-option-list:hover{
        background: lightgrey;
    }
    .ths-option-list a{
        text-decoration: none;
        color: #575757;
        display: block;
        padding: 1rem;
    }
    .tab-button{
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: center;
        cursor: pointer;
        margin-top: 0.75rem;
        margin-right: 0.75rem;
        font-family: poppins;
        font-size: 1rem;
        font-weight: 600;
        color: #575757;
        padding: 0.5rem;
        padding-left: 0;
        padding-right: 1rem;
        user-select: none;
        line-height: 1;
        text-decoration: none;
    }
    .tb-num{
        display: flex;
        justify-content: center;
        font-size: 0.5rem;
        padding: 0.36rem;
        width: 1.2rem;
        height: 1.2rem;
        border: 1px solid #F2CB9C;
        border-radius: 1rem;
        margin-left: 0.5rem;
    }
    .tb-active{
        color: #1B4674;
        background: #FEDF87;
        border-radius: 5px;
    }
    .tb-active .tb-num{
        background: #EA476A;
        color: #ffffff;
        border: 1px solid #EA476A;
    }
    .section-part{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        /* border: 1px solid green; */
        transition: 0.2s;
    }
    .section-part-sp{
        background: #FEF8F0;
    }
    .section-part-side{
        width: 50%;
        /* padding-bottom: 2rem; */
        transition: 0.2s;
    }
    .sps-middle{
        background: #FEF8F0;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .sm-left{
        padding-top: 2rem;
        padding-left: 11.25rem;
        padding-bottom: 2rem;
        transition: 0.2s;
    }
    .sm-right{
        padding-top: 2rem;
        padding-right: 11.25rem;
        padding-bottom: 2rem;
        transition: 0.2s;
    }
    .section-part-right{
        width: 50%;
    }
    .section-header{
        padding-bottom: 2.5rem;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
    }
    .section-content{
        width: 100%;
        height: 100%;
    }
    .sc-inner{
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow: auto;
    }
    .sc-inner::-webkit-scrollbar{
        display: none;
    }
    .sci-part{
        position: relative;
        color: #ffffff;
        width: 50%;
        min-width: 15rem;
        height: 22rem;
        padding: 1%;
        transition: 0.2s;
    }
    .tcp-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .tcp-img img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-label{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 2rem;
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .sci-label span{
        background: #75C6EF;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
        white-space: nowrap;
        max-width: 100px;
    }
    .sci-label-sp span{
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 5px;
    }
    .sci-caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0.5rem;
        padding-top: 1.5rem;
    }
    .sci-caption-inner{
        padding: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-caption a{
        text-decoration: none;
        color: #ffffff;
    }
    .sci-caption h3{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1.2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sci-caption-date{
        margin: 0;
        margin-top: 0.5rem;
    }
    .sc-browse{
        margin-top: 1.6875rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .sc-browse a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1410px){
        .tab-head{
            padding-left: 5%;
            padding-right: 5%;
        }
        .th-select{
            left: 2.5%;
        }
        .sm-left{
            padding-left: 5%;
        }
        .sm-right{
            padding-right: 5%;
        }
        .section-part{
            padding-bottom: 0;
        }
        .section-part-side{
            width: 50%;
        }
        .sci-part{
            min-width: 15rem;
        }
    }
    @media only screen and (max-width: 1067px){
        .tab-head{
            padding-top: 3.6rem;
        }
        .th-select{
            left: 5%;
        }
        .section-part-side{
            width: 100%;
        }
        .sm-left{
            padding-right: 5%;
        }
        .sm-right{
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 685px){
        .th-select{
            width: 95%;
            left: 2.5%;
        }
        .ths-option{
            top: 132%;
        }
        .tab-head{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sm-left{
            padding-left: 1.5%;
            padding-right: 2.5%;
        }
        .sm-right{
            padding-left: 1.5%;
            padding-right: 2.5%;
        }
        .sci-part{
            height: 17rem;
            min-width: 17rem;
        }
        .sci-label{
            padding: 5%;
            padding-top: 10%;
        }
    }
</style>