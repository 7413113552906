<template>
    <div class="content">
        <ExploreHeader />
        <ExploreBrowse />
        <ExploreLearning />
    </div>
</template>

<script setup>
    import ExploreHeader from '@/components/explore/ExploreHeader.vue'
    import ExploreBrowse from '@/components/explore/ExploreBrowse.vue'
    import ExploreLearning from '@/components/explore/ExploreLearning.vue'
    document.title = 'Explore - P3K Melinda Hospital';
</script>