<template>
    <section>
        <div class="section-header">
            <p class="sm-title">Melinda Hospital P3K</p>
            <h2>We make learning easy with helpful, approachable instructions.</h2>
        </div>
        <div class="section-content">
            <div class="sc-inner">
                <div class="sci-part">
                    <div class="sp-inner">
                        <p class="sp-description">"I've been yelling for a while about how P3K is doing the best consistent service journalism on the web right now."</p>
                        <div class="sp-footer">
                            <div class="sp-img">
                                <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda">
                            </div>
                            <div class="sp-text">
                                <h3>John Doe</h3>
                                <p>Technology Writer, The Atlantic</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sp-inner">
                        <p class="sp-description">"I've been yelling for a while about how P3K is doing the best consistent service journalism on the web right now."</p>
                        <div class="sp-footer">
                            <div class="sp-img">
                                <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda">
                            </div>
                            <div class="sp-text">
                                <h3>John Doe</h3>
                                <p>Technology Writer, The Atlantic</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sp-inner">
                        <p class="sp-description">"I've been yelling for a while about how P3K is doing the best consistent service journalism on the web right now."</p>
                        <div class="sp-footer">
                            <div class="sp-img">
                                <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda">
                            </div>
                            <div class="sp-text">
                                <h3>John Doe</h3>
                                <p>Technology Writer, The Atlantic</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ExploreLearning',
    methods: {
        // 
    }
}
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 36rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #FFF1DF;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
    }
    .section-header{
        position: relative;
        padding-left: 26%;
        padding-right: 26%;
        padding-bottom: 2.5rem;
        text-align: center;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .section-content{
        width: 100%;
        height: 100%;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
    }
    .sc-inner::-webkit-scrollbar{
        display: none;
    }
    .sci-part{
        position: relative;
        color: #1C4677;
        width: 33.33%;
        min-width: 19rem;
        padding: 1%;
    }
    .sp-inner{
        background: #ffffff;
        padding: 3rem;
        border-radius: 5px;
    }
    .sp-description{
        font-weight: 700;
        font-size: 1.2rem;
        font-style: italic;
    }
    .sp-footer{
        display: flex;
        width: 100%;
        margin-top: 2rem;
    }
    .sp-img{
        min-width: 50px;
        width: 50px;
    }
    .sp-img img{
        background: orange;
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    .sp-text{
        width: 75%;
        padding-left: 1rem;
    }
    .sp-text h3{
        font-weight: 700;
    }
    .sp-text p{
        color: #575757;
        font-weight: 400;
        margin-top: 0.5rem;
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sc-inner{
            padding-left: 5%;
            padding-right: 5%;
        }
        .tab-content{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1585px){
        .sci-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 1015px){
        .sc-inner{
            flex-wrap: nowrap;
            overflow: auto;
        }
        .sci-part{
            width: 50%;
        }
    }
    @media only screen and (max-width: 685px){
        section h2{
            font-size: 2rem;
        }
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sc-inner{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .tab-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-part{
            width: 100%;
        }
    }
</style>