<template>
    <ArticleDetailSkeleton v-if="!artikel" />
    <section v-else>
        <div class="section-header">
            <div class="sh-inner">
                <span class="section-label">Melinda Hospital P3K Article</span>
            </div>
        </div>
        <div class="section-content" v-for="article in this.artikel" :key="article.id">
            <div class="sc-inner">
                <div class="sci-left">
                    <div class="sl-head">
                        <h1 class="main-title">{{ article.title }}</h1>
                        <p class="p-author">
                            <span class="pa-param">Co-authored by</span><span class="pa-value">Fuad Hasan</span>
                        </p>
                    </div>
                    <div class="sl-body">
                        <div class="sl-content">
                            <div class="slc-img">
                                <img v-bind:src="this.imgsrc + article.picture" alt="p3k melinda" />
                            </div>
                            <div class="slc-description" v-html="article.description"></div>
                        </div>
                    </div>
                    <div class="sc-more">
                        <router-link to="">Load more detail</router-link>
                    </div>
                </div>
                <div class="sci-right">
                    <div class="sr-newsletter">
                        <h2>Sign up to our newsletter</h2>
                        <p>Get the latest articles on all things data delivered straight to your inbox</p>
                        <div class="srn-input">
                            <input type="text" placeholder="Your work email" />
                        </div>
                        <div class="srn-button">
                            <span>Subscribe</span>
                        </div>
                    </div>
                    <div class="sc-share">
                        <div class="sc-share-inner">
                            <div class="scs-text">
                                <p>Share this articles</p>
                            </div>
                            <div class="scs-icon">
                                <div class="scs-part">
                                    <a href="https://www.facebook.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-facebook.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.twitter.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-twitter.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.linkedin.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-linkedin.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.instagram.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-instagram.png" alt="food traveller">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sr-categories">
                        <h2>Categories</h2>
                        <ul>
                            <li v-for="category in categories" :key="category.id">
                                <router-link :to="'/article/category/' + category.id" >{{ category.name }}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="sr-tags">
                        <h2>Tags</h2>
                        <div class="srt-inner">
                            <div class="srt-part">
                                <router-link to="">CoronaVac</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">WHO</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">Technology</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">JournalMedic</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">Pfizer</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">SWAB</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">BPJS</router-link>
                            </div>
                            <div class="srt-part">
                                <router-link to="">Asuransi</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    import ArticleDetailSkeleton from '@/components/skeletons/ArticleDetailSkeleton.vue'
    export default {
        name: 'ArticleContent',
        data() {
            return {
                pageTitle: null,
                artikel: [],
                categories: [],
                imgsrc: process.env.VUE_APP_ROOT_API + 'images/articles/',
                id: this.$route.params.id
            }
        },
        components: {
            ArticleDetailSkeleton
        },
        methods: {
            setArticle(){
                const store = useArticleStore();
                store.fetchArticleDetail(this.id);
                this.artikel = computed(() => store.articleDetail);
            },
            fetchCategories(){
                useArticleStore().fetchArticleCategories();
                this.categories = computed(() => useArticleStore().articleCategories);
            },
            fetchRelated(){
                if(useArticleStore().articleDetailRelated.length < 1){
                    useArticleStore().fetchAdr(this.id);
                }
                // this.related = computed(() => useArticleStore().adRelated);
            }
        },
        mounted() {
            this.setArticle();
            this.fetchCategories();
            this.fetchRelated();
        },
        setup() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #FEF8F0;
    }
    .section-header{
        padding-top: 5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .section-label{
        /* padding: 0.5rem; */
        color: #75C6EF;
        /* background: #FEDF87; */
        font-family: poppins;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 5px;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        color: #1B4674;
        line-height: 1.1;
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .p-author{
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
    }
    .pa-param{
        color: #575757;
        font-size: 0.875rem;
        font-weight: 400;
        padding-right: 1rem;
    }
    .pa-value{
        color: #75C6EF;
        font-size: 0.875rem;
        font-weight: 700;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        margin-top: 0.5rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .sci-left{
        width: 60%;
        color: #575757;
        transition: 0.2s;
    }
    .sl-body{
        padding-top: 2rem;
    }
    .sl-content{
        font-size: 1.2rem;
        text-align: justify;
    }
    .slc-img{
        margin-bottom: 2rem;
        min-height: 200px;
    }
    .slc-img img{
        width: 100%;
    }
    .sc-part{
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        background: #ffffff;
    }
    .sc-part-head{
        display: flex;
        flex-wrap: nowrap;
        padding: 2rem;
    }
    .sph-num{
        min-width: 100px;
        width: 6.25rem;
        min-height: 100px;
        height: 6.25rem;
        display: flex;
        justify-content: center;
        font-family: poppins;
        font-size: 1.5rem;
        font-weight: 700;
        background: #EA476A;
        color: #ffffff;
        border-radius: 100px;
        padding-top: 1.85rem;
    }
    .sph-text{
        padding-left: 2rem;
    }
    .sph-text p{
        font-size: 1.5rem;
        font-weight: 400;
        margin-top: 0.5rem;
    }
    .sph-text h2{
        color: #1B4674;
        font-family: poppins;
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 0.5rem;
    }
    .sc-part-body{
        width: 100%;
    }
    .spb-img{
        width: 100%;
    }
    .spb-img img{
        width: 100%;
    }
    .spb-description{
        padding: 2rem;
    }
    .sc-more{
        margin-top: 1.6875rem;
        text-align: center;
    }
    .sc-more a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    .sci-right{
        width: 40%;
        padding-left: 5rem;
        transition: 0.2s;
    }
    .sr-newsletter{
        background: #ffffff;
        width: 100%;
        padding: 2rem;
        margin-bottom: 2rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        transition: 0.2s;
    }
    .sci-right h2{
        color: #1B4674;
        font-family: poppins;
        font-size: 1.125rem;
        font-weight: 700;
    }
    .sr-newsletter p{
        color: #575757;
        font-size: 0.875rem;
        font-weight: 400;
        margin-top: 1rem;
    }
    .srn-input input{
        border: none;
        border-bottom: 1px solid #D3D3D3;
        margin-top: 1rem;
        width: 100%;
        padding: 0.5rem;
        outline: none;
    }
    .srn-button{
        width: 100%;
        background: #EA476A;
        color: #ffffff;
        text-align: center;
        padding: 1rem;
        margin-top: 1rem;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.2s;
    }
    .srn-button:hover{
        background: #d44161;
    }
    .sc-share{
        margin-top: 2rem;
        width: 100%;
        transition: 0.2s;
    }
    .sc-share-inner{
        display: flex;
        flex-wrap: nowrap;
    }
    .scs-text{
        color: #575757;
    }
    .scs-icon{
        display: flex;
        padding: 0.75rem;
    }
    .scs-part{
        margin-right: 1rem;
    }
    .sr-categories{
        width: 100%;
        margin-bottom: 2rem;
        transition: 0.2s;
    }
    .sr-categories ul{
        list-style-type: none;
        padding: 0;
    }
    .sr-categories ul li{
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-weight: 600;
        border-bottom: 1px solid lightgrey;
    }
    .sr-categories a{
        text-decoration: none;
        color: #575757;
    }
    .sr-tags{
        width: 100%;
        transition: 0.2s;
    }
    .srt-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;
    }
    .srt-part{
        background: #FCE8CE;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0.5rem;
        margin-left: 0;
        border-radius: 50px;
    }
    .srt-part a{
        color: #575757;
        text-decoration: none;
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-top: 0;
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .sci-left{
            width: 100%;
        }
        .main-title{
            font-size: 2.6rem;
        }
        .p-author{
            width: 100%;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-left: 0;
            margin-top: 2rem;
        }
        .sr-newsletter{
            width: 100%;
        }
        .sc-share{
            width: 100%;
        }
        .sr-categories{
            width: 50%;
            padding-right: 5%;
        }
        .sr-tags{
            width: 50%;
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 710px){
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .main-title{
            font-size: 1.7rem;
        }
        .sr-categories{
            width: 100%;
            padding-right: 0;
        }
        .sr-tags{
            width: 100%;
            padding-left: 0;
        }
    }
</style>