<template>
    <div class="content">
        <ContactHeader />
        <ContactForm />
        <ContactMaps />
    </div>
</template>

<script setup>
    import ContactHeader from '@/components/contact-us/ContactHeader.vue'
    import ContactForm from '@/components/contact-us/ContactForm.vue'
    import ContactMaps from '@/components/contact-us/ContactMaps.vue'
    document.title = 'Contact Us - P3K Melinda Hospital';
</script>