<template>
    <section>
        <div class="section-header">
            <div class="th-select">
                <!-- <p class="ths-title">Choose Categories</p> -->
                <div class="ths-button float-button" @click="thsOption">
                    <div class="ths-button-text float-button" id="ths-button-text">Tips Pertolongan Pertama</div>
                    <div class="ths-button-icon float-button">
                        <img class="tbi-img float-button" id="tbi-img" src="../../assets/images/icons/arrow-1.png" alt="p3k melinda">
                    </div>
                </div>
                <div class="ths-option float" id="ths-option">
                    <div class="ths-option-list" v-for="category in articleCategories" :key="category.id" @click="setFabc(category.id, category.name)">
                        <router-link :to="'/explore/category/' + category.id" >{{ category.name }}</router-link>
                    </div>
                </div>
            </div>
            <div class="th-back">
                <router-link to="/explore" >Back to Explore Page</router-link>
            </div>
            <p class="sm-title">Melinda Hospital P3K</p>
            <h2>Featured First Aid</h2>
        </div>
        <HomeFeaturedSkeleton v-if="!firstAids" />
        <div class="section-content">
            <div class="sc-inner">
                <div class="sci-part" v-for="fa in firstAids" :key="fa.id">
                    <div class="tcp-img">
                        <img v-bind:src="imgsrc + fa.picture" alt="p3k melinda" />
                        <div class="sci-gradient"></div>
                    </div>
                    <div class="sci-label">
                        <span>{{ fa.category_name }}</span>
                    </div>
                    <div class="sci-caption">
                        <div class="sci-caption-inner">
                            <router-link :to="'/explore/detail/' + fa.id" >
                                <h3>{{ fa.title }}</h3>
                            </router-link>
                            <p class="sci-caption-date">{{ epochDMY(Date.parse(fa.created_at)) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sc-more">
                <router-link to="">More Articles</router-link>
            </div>
        </div>
    </section>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useFirstAidStore } from "../../stores/firstaid";
    import HomeFeaturedSkeleton from '@/components/skeletons/HomeFeaturedSkeleton.vue'
    export default {
        name: 'ExploreCategoryContent',
        data(){
            return {
                articleCategories: computed(() => useFirstAidStore().articleCategories),
                firstAids: null,
                imgsrc: process.env.VUE_APP_ROOT_API + 'images/first-aids/steps/',
                id: this.$route.params.id
            }
        },
        methods: {
            thsOption(){
                if(document.getElementById('ths-option').style.display == 'block'){
                    document.getElementById('ths-option').style.display = 'none';
                    document.getElementById('tbi-img').style.transform = 'rotate(0)';
                }else{
                    document.getElementById('ths-option').style.display = 'block';
                    document.getElementById('tbi-img').style.transform = 'rotate(541deg)';
                }
            },
            setExploreCategory(){
                useFirstAidStore().fetchFabc(this.id, 15); // (category, limit) =====
                useFirstAidStore().fetchArticleCategories();
                this.firstAids = computed(() => useFirstAidStore().firstAidsByCategory);
            },
            setFabc(id, name){
                useFirstAidStore().fetchFabc(id, 15);
                document.getElementById('ths-button-text').innerHTML = name;
            },
            epochDMY(ts){
                const months = ["Januari", "Pebruari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
                var dt = new Date(ts);
                var d = dt.getDay();
                var m = months[dt.getMonth()];
                var y = dt.getFullYear();
                return d + ' ' + m +' ' + y;
            }
        },
        components: {
            HomeFeaturedSkeleton
        },
        setup() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        mounted() {
            this.setExploreCategory();
        }
    }
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 36rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        transition: 0.2s;
    }
    section p{
        margin-top: 1.6875rem;
        font-size: 1.5rem;
    }
    .section-header{
        position: relative;
        padding-top: 4.1rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        /* padding-bottom: 2.5rem; */
        transition: 0.2s;
    }
    .th-select{
        position: absolute;
        top: 0;
        left: 11.75rem;
        height: 4.1rem;
        width: 40%;
        user-select: none;
        transition: 0.2s;
    }
    .ths-title{
        font-size: 1rem;
        color: #575757;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .ths-button{
        display: flex;
        background: #ffffff;
        padding: 1rem;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 whitesmoke, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        cursor: pointer;
        color: #575757;
    }
    .ths-button-text{
        width: 90%;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 1rem;
    }
    .ths-button-icon{
        width: 10%;
        text-align: right;
    }
    .tbi-img{
        transition: 0.5s;
    }
    .ths-option{
        position: absolute;
        top: 90%;
        left: 0;
        width: 100%;
        background: #ffffff;
        z-index: 1;
        border: 1px solid whitesmoke;
        border-top: none;
        border-radius: 5px;
        display: none;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 whitesmoke, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .ths-option-list{
        cursor: pointer;
        transition: 0.2s;
    }
    .ths-option-list:hover{
        background: lightgrey;
    }
    .ths-option-list a{
        text-decoration: none;
        color: #575757;
        display: block;
        padding: 1rem;
    }
    .th-back{
        position: absolute;
        top: 0;
        right: 11.75rem;
        max-width: 40%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 1rem;
        transition: 0.2s;
    }
    .th-back a{
        color: #575757;
        font-weight: 600;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .section-content{
        width: 100%;
        height: 100%;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
        transition: 0.2s;
    }
    .sc-inner::-webkit-scrollbar{
        display: none;
    }
    .sci-part{
        position: relative;
        color: #ffffff;
        width: 25%;
        min-width: 19rem;
        min-height: 22rem;
        padding: 1%;
        transition: 0.2s;
    }
    .tcp-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .tcp-img img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-label{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 3rem;
        font-family: poppins;
        font-size: 1rem;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sci-label span{
        background: #75C6EF;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
    }
    .sci-label-sp span{
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 5px;
    }
    .sci-caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        padding-top: 1.5rem;
    }
    .sci-caption-inner{
        padding: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-caption a{
        text-decoration: none;
        color: #ffffff;
    }
    .sci-caption h3{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1.2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sci-caption-date{
        margin: 0;
        margin-top: 0.5rem;
    }
    .sc-more{
        margin-top: 1.6875rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        text-align: center;
        transition: 0.2s;
    }
    .sc-more a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1585px){
        .sci-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 1500px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .th-select{
            left: 5%;
        }
        .th-back{
            right: 5%;
        }
        .sc-inner{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sc-more{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .sci-part{
            width: 50%;
            height: 22rem;
        }
    }
    @media only screen and (max-width: 685px){
        section h2{
            margin-bottom: 0;
            font-size: 2rem;
        }
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .th-select{
            left: 2.5%;
        }
        .sc-inner{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .th-back{
            top: 1rem;
            right: 2.5%;
        }
        .sci-part{
            width: 100%;
            margin-top: 1rem;
        }
        .sci-label{
            padding-left: 2rem;
        }
        .sc-more{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
    }
    @media only screen and (max-width: 600px){
        .th-select{
            width: 50%;
        }
        .ths-option{
            width: 190%;
        }
    }
    @media only screen and (max-width: 360px){
        .sc-inner{
            flex-wrap: nowrap;
            overflow: auto;
            overflow-y: hidden;
        }
        .sci-part{
            padding-right: 1%;
            min-width: 22rem;
        }
    }
</style>