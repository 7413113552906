<template>
    <section>
        <div class="section-header">
            <p class="sm-title">Melinda Hospital P3K</p>
            <h2>Articles</h2>
        </div>
        <div class="section-tab">
            <div class="tab-head" id="tab-head">
                <div class="tab-head-inner">
                    <div class="cov-tab-button" v-for="(category, index) in categories" :key="category.id">
                        <div class="tab-button tb-active" :id="'button-' + category.tab_id"  @click="selectTab(category.tab_id, 'button-' + category.tab_id)" v-if="index == 0">
                            <div class="tb-text">{{ category.name }}</div>
                        </div>
                        <div class="tab-button" :id="'button-' + category.tab_id"  @click="selectTab(category.tab_id, 'button-' + category.tab_id)" v-else>
                            <div class="tb-text">{{ category.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-body">
                <div class="tab-content tc-first" id="tab-1">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesTppTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesTppBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesTppBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-2">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesPdrTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesPdrBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesPdrBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-3">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesStrTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesStrBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesStrBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-4">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesSrjTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesSrjBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesSrjBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-5">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesPttTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesPttBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesPttBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-6">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesTsdTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesTsdBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesTsdBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-7">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesCdkTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesCdkBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesCdkBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-8">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesLkbTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesLkbBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesLkbBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-9">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesEplTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesEplBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesEplBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-10">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesDbtTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesDbtBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesDbtBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-11">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesKrcTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesKrcBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesKrcBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-12">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesSraTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesSraBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesSraBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-13">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesPnyTop" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articlesPnyBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articlesPnyBtr" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-14">
                    <div class="tc-top">
                        <div class="sci-part" v-for="(am, index) in articlesMix" :key="am.id">
                            <div class="sp-inner" v-if="index == 0">
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                            <div class="sp-inner spi-even" v-else>
                                <p class="sp-category">{{ am.name }}</p>
                                <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >
                                    <h2>{{ am.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-description">
                                    <p v-if="am.meta_description.length > 100">{{ am.meta_description.slice(0, 100) + '.....' }}</p>
                                    <p v-else>{{ am.meta_description }}</p>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + am.id" @click="setNewRelated(am.id)" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-bottom">
                        <div class="tcb-part tcb-left" v-for="article in articleBtBig" :key="article.id">
                            <div class="tl-image">
                                <img :src="imgsrc + article.picture" alt="p3k melinda">
                            </div>
                            <div class="sp-inner sp-inner-tl">
                                <p class="sp-category">{{ article.name }}</p>
                                <router-link :to="detailpath + article.id" >
                                    <h2>{{ article.title }}</h2>
                                </router-link>
                                <div class="sp-author">
                                    <div class="spa-icon">
                                        <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                    </div>
                                    <div class="spa-text">
                                        <p>Ahmad Pratama on 22 July 2022</p>
                                    </div>
                                </div>
                                <div class="sp-more">
                                    <router-link :to="detailpath + article.id" >Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="tcb-part tcb-right">
                            <div class="tr-inner">
                                <div class="sp-inner sp-inner-tr" v-for="article in articleBtRight" :key="article.id">
                                    <p class="sp-category">{{ article.name }}</p>
                                    <router-link :to="detailpath + article.id" >
                                        <h2>{{ article.title }}</h2>
                                    </router-link>
                                    <div class="sp-author">
                                        <div class="spa-icon">
                                            <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                                        </div>
                                        <div class="spa-text">
                                            <p>Ahmad Pratama on 22 July 2022</p>
                                        </div>
                                    </div>
                                    <div class="sp-description">
                                        <p v-if="article.meta_description.length > 100">{{ article.meta_description.slice(0, 100) + '.....' }}</p>
                                        <p v-else>{{ article.meta_description }}</p>
                                    </div>
                                    <div class="sp-more">
                                        <router-link :to="detailpath + article.id" >Readmore</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    export default {
        name: 'ArticleList',
        data() {
            return {
                categories: [],
                articlesTppTop: [],
                articlesTppBig: [],
                articlesTppBtr: [],
                articlesPdrTop: [],
                articlesPdrBig: [],
                articlesPdrBtr: [],
                articlesStrTop: [],
                articlesStrBig: [],
                articlesStrBtr: [],
                articlesSrjTop: [],
                articlesSrjBig: [],
                articlesSrjBtr: [],
                articlesPttTop: [],
                articlesPttBig: [],
                articlesPttBtr: [],
                articlesTsdTop: [],
                articlesTsdBig: [],
                articlesTsdBtr: [],
                articlesCdkTop: [],
                articlesCdkBig: [],
                articlesCdkBtr: [],
                articlesLkbTop: [],
                articlesLkbBig: [],
                articlesLkbBtr: [],

                articlesEplTop: [],
                articlesEplBig: [],
                articlesEplBtr: [],

                articlesDbtTop: [],
                articlesDbtBig: [],
                articlesDbtBtr: [],

                articlesKrcTop: [],
                articlesKrcBig: [],
                articlesKrcBtr: [],

                articlesSraTop: [],
                articlesSraBig: [],
                articlesSraBtr: [],

                articlesPnyTop: [],
                articlesPnyBig: [],
                articlesPnyBtr: [],
                // ========================
                articlesMix: null,
                articleBtBig: null,
                articleBtRight: null,
                detailpath: '/article/detail/',
                imgsrc: process.env.VUE_APP_ROOT_API + 'images/articles/'
            }
        },
        methods: {
            selectTab(a, b){
                var tb = document.getElementsByClassName('tab-button');
                var tc = document.getElementsByClassName('tab-content');
                var i;
                for(i = 0; i < tc.length; i++){
                    tc[i].style.display = 'none';
                    tb[i].classList.remove('tb-active');
                }
                document.getElementById(a).style.display = 'block';
                document.getElementById(b).classList.add('tb-active');
            },
            fetchCategories(){
                this.categories = computed(() => useArticleStore().articleCategories);
                useArticleStore().fetchArticleCategories();
            },
            fetchArticlesTpp(){
                if(useArticleStore().articlesTpp.length < 1){
                    useArticleStore().fetchTpp(1, 15);
                }
                this.articlesTppTop = computed(() => useArticleStore().articlesTppTop);
                this.articlesTppBig = computed(() => useArticleStore().articleTppBig);
                this.articlesTppBtr = computed(() => useArticleStore().articlesTppBtr);
            },
            fetchArticlesPdr(){
                if(useArticleStore().articlesPdr.length < 1){
                    useArticleStore().fetchPdr(2, 15);
                }
                this.articlesPdrTop = computed(() => useArticleStore().articlesPdrTop);
                this.articlesPdrBig = computed(() => useArticleStore().articlePdrBig);
                this.articlesPdrBtr = computed(() => useArticleStore().articlesPdrBtr);
            },
            fetchArticlesStr(){
                if(useArticleStore().articlesStr.length < 1){
                    useArticleStore().fetchStr(3, 15);
                }
                this.articlesStrTop = computed(() => useArticleStore().articlesStrTop);
                this.articlesStrBig = computed(() => useArticleStore().articleStrBig);
                this.articlesStrBtr = computed(() => useArticleStore().articlesStrBtr);
            },
            fetchArticlesSrj(){
                if(useArticleStore().articlesSrj.length < 1){
                    useArticleStore().fetchSrj(4, 15);
                }
                this.articlesSrjTop = computed(() => useArticleStore().articlesSrjTop);
                this.articlesSrjBig = computed(() => useArticleStore().articleSrjBig);
                this.articlesSrjBtr = computed(() => useArticleStore().articlesSrjBtr);
            },
            fetchArticlesPtt(){
                if(useArticleStore().articlesPtt.length < 1){
                    useArticleStore().fetchPtt(5, 15);
                }
                this.articlesPttTop = computed(() => useArticleStore().articlesPttTop);
                this.articlesPttBig = computed(() => useArticleStore().articlePttBig);
                this.articlesPttBtr = computed(() => useArticleStore().articlesPttBtr);
            },
            fetchArticlesTsd(){
                if(useArticleStore().articlesTsd.length < 1){
                    useArticleStore().fetchTsd(6, 15);
                }
                this.articlesTsdTop = computed(() => useArticleStore().articlesTsdTop);
                this.articlesTsdBig = computed(() => useArticleStore().articleTsdBig);
                this.articlesTsdBtr = computed(() => useArticleStore().articlesTsdBtr);
            },
            fetchArticlesCdk(){
                if(useArticleStore().articlesCdk.length < 1){
                    useArticleStore().fetchCdk(7, 15);
                }
                this.articlesCdkTop = computed(() => useArticleStore().articlesCdkTop);
                this.articlesCdkBig = computed(() => useArticleStore().articleCdkBig);
                this.articlesCdkBtr = computed(() => useArticleStore().articlesCdkBtr);
            },
            fetchArticlesLkb(){
                if(useArticleStore().articlesLkb.length < 1){
                    useArticleStore().fetchLkb(8, 15);
                }
                this.articlesLkbTop = computed(() => useArticleStore().articlesLkbTop);
                this.articlesLkbBig = computed(() => useArticleStore().articleLkbBig);
                this.articlesLkbBtr = computed(() => useArticleStore().articlesLkbBtr);
            },
            fetchArticlesEpl(){
                if(useArticleStore().articlesEpl.length < 1){
                    useArticleStore().fetchEpl(9, 15);
                }
                this.articlesEplTop = computed(() => useArticleStore().articlesEplTop);
                this.articlesEplBig = computed(() => useArticleStore().articleEplBig);
                this.articlesEplBtr = computed(() => useArticleStore().articlesEplBtr);
            },
            fetchArticlesDbt(){
                if(useArticleStore().articlesDbt.length < 1){
                    useArticleStore().fetchDbt(10, 15);
                }
                this.articlesDbtTop = computed(() => useArticleStore().articlesDbtTop);
                this.articlesDbtBig = computed(() => useArticleStore().articleDbtBig);
                this.articlesDbtBtr = computed(() => useArticleStore().articlesDbtBtr);
            },
            fetchArticlesKrc(){
                if(useArticleStore().articlesKrc.length < 1){
                    useArticleStore().fetchKrc(11, 15);
                }
                this.articlesKrcTop = computed(() => useArticleStore().articlesKrcTop);
                this.articlesKrcBig = computed(() => useArticleStore().articleKrcBig);
                this.articlesKrcBtr = computed(() => useArticleStore().articlesKrcBtr);
            },
            fetchArticlesSra(){
                if(useArticleStore().articlesSra.length < 1){
                    useArticleStore().fetchSra(12, 15);
                }
                this.articlesSraTop = computed(() => useArticleStore().articlesSraTop);
                this.articlesSraBig = computed(() => useArticleStore().articleSraBig);
                this.articlesSraBtr = computed(() => useArticleStore().articlesSraBtr);
            },
            fetchArticlesPny(){
                if(useArticleStore().articlesPny.length < 1){
                    useArticleStore().fetchPny(13, 15);
                }
                this.articlesPnyTop = computed(() => useArticleStore().articlesPnyTop);
                this.articlesPnyBig = computed(() => useArticleStore().articlePnyBig);
                this.articlesPnyBtr = computed(() => useArticleStore().articlesPnyBtr);
            },
            setNewRelated(id){
                useArticleStore().fetchAdr(id);
            },
            //===============
            fetchArticlesMix(){
                this.articlesMix = computed(() => useArticleStore().articlesMix);
                useArticleStore().fetchArticlesMix(2);
            },
            fetchArticleBtBig(){
                this.articleBtBig = computed(() => useArticleStore().articleBtBig);
                useArticleStore().fetchArticleBtBig(1);
            },
            fetchArticleBtRight(){
                this.articleBtRight = computed(() => useArticleStore().articleBtRight);
                useArticleStore().fetchArticleBtRight(2);
            }
        },
        setup() {
            // document.body.scrollTop = 0;
            // document.documentElement.scrollTop = 0;
        },
        mounted() {
            this.fetchCategories();
            this.fetchArticlesTpp();
            this.fetchArticlesPdr();
            this.fetchArticlesStr();
            this.fetchArticlesSrj();
            this.fetchArticlesPtt();
            this.fetchArticlesTsd();
            this.fetchArticlesCdk();
            this.fetchArticlesLkb();
            this.fetchArticlesEpl();
            this.fetchArticlesDbt();
            this.fetchArticlesKrc();
            this.fetchArticlesSra();
            this.fetchArticlesPny();
            // ========================================
            this.fetchArticlesMix();
            this.fetchArticleBtBig();
            this.fetchArticleBtRight();
        }
    }
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 36rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #FEF8F0;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
    }
    section p{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1.5rem;
    }
    .section-header{
        position: relative;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
        transition: 0.2s;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .sh-more{
        position: absolute;
        right: 12.25rem;
        bottom: 0;
    }
    .section-tab{
        width: 100%;
    }
    .tab-head{
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .tab-head-inner{
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 2rem;
        transition: 0.2s;
    }
    .tab-button{
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: left;
        cursor: pointer;
        margin-top: 0.75rem;
        margin-right: 0.75rem;
        font-family: poppins;
        font-size: 1.125rem;
        font-weight: 600;
        color: #1B4674;
        padding: 0.5rem;
        padding-left: 0;
        /* padding-right: 1rem; */
        user-select: none;
        line-height: 1;
    }
    .tb-active{
        color: #75C6EF;
        /* background: #FEDF87; */
        /* border-radius: 5px; */
    }
    .tab-body{
        width: 100%;
        min-height: 10rem;
    }
    .tab-content{
        display: none;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 2rem;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
        /* border: 1px solid green; */
        transition: 0.2s;
    }
    .tc-first{
        display: flex;
    }
    .tc-top{
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
        width: 100%;
        transition: 0.2s;
    }
    .sci-part{
        width: 50%;
        /* transition: 0.2s; */
    }
    .sci-part a{
        text-decoration: none;
        color: #1B4674;
    }
    .sci-part h2{
        font-family: poppins;
        font-weight: 700;
        font-size: 2rem;
        margin: 0;
        margin-top: 1rem;
        transition: 0.2s;
    }
    .sp-inner{
        padding-top: 2rem;
        padding-right: 2rem;
        margin-bottom: 2rem;
    }
    .spi-even{
        background: #ffffff;
        padding: 2rem;
        margin-left: 5rem;
        transition: 0.2s;
    }
    .sp-category{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
    }
    .sp-author{
        display: flex;
        margin-top: 1rem;
    }
    .spa-icon{
        width: 50px;
    }
    .spa-icon img{
        background: orange;
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    .spa-text{
        color: #575757;
        margin-left: 1rem;
        font-size: 0.875rem;
        font-weight: 700;
        padding: 0.5rem;
    }
    .spa-text p{
        margin: 0;
        font-size: 1rem;
    }
    .sp-description p{
        color: #575757;
        font-size: 1rem;
        transition: 0.2s;
    }
    .sp-more{
        margin-top: 1rem;
    }
    .sp-more a{
        text-decoration: underline;
        color: #575757;
        font-weight: 600;
    }
    .tc-bottom{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 2rem;
        padding: 1rem;
        transition: 0.2s;
    }
    .tcb-part{
        width: 50%;
        height: 50rem;
        /* border: 1px solid green; */
        transition: 0.2s;
    }
    .tcb-left{
        position: relative;
        border-radius: 10px;
    }
    .tl-image{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 10px;
        overflow: hidden;
    }
    .tl-image img{
        width: 100%;
        height: 100%;
    }
    .sp-inner-tl{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        padding: 2rem;
        color: #ffffff;
        /* transition: 0.2s; */
        /* font-size: 1.7rem; */
    }
    .sp-inner-tl a{
        text-decoration: none;
        color: #ffffff;
    }
    .sp-inner-tl p{
        color: #ffffff;
    }
    .sp-inner-tl .sp-more a{
        color: #ffffff;
        text-decoration: underline;
    }
    .tcb-right{
        padding: 1rem;
        padding-left: 5rem;
    }
    .sp-inner-tr{
        margin-bottom: 3.6rem;
        padding-top: 0;
    }
    .sp-inner-tr a{
        text-decoration: none;
        color: #1B4674;
    }
    .sp-inner-tr .sp-category{
        font-size: 1.125rem;
    }
    .sp-inner-tr h2{
        font-size: 1.75rem;
    }
    .sp-inner-tr .sp-description{
        font-size: 0.875rem;
    }
    .sp-inner-tr .sp-more a{
        color: #575757;
        text-decoration: underline;
    }
    @media only screen and (max-width: 1485px){
        .sci-part h2{
            font-size: 1.5rem;
        }
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .tab-head{
            padding-left: 5%;
            padding-right: 5%;
        }
        .tab-content{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1141px){
        .tab-head-inner{
            flex-wrap: nowrap;
            overflow: auto;
        }
        .tab-head-inner::-webkit-scrollbar{
            display: none;
        }
        .tcb-part{
            height: auto;
        }
    }
    @media only screen and (max-width: 955px){
        .tc-top{
            padding: 0;
        }
        .sci-part{
            width: 100%;
        }
        .spi-even{
            margin-left: 0;
        }
        .tc-bottom{
            padding: 0;
        }
        .tcb-part{
            width: 100%;
        }
        .sp-inner-tl{
            padding: 5%;
            margin-bottom: 0;
        }
        .sp-inner-tl h2{
            font-size: 2rem;
            margin-top: 1rem;
            line-height: 1;
        }
        .tcb-right{
            padding-left: 0;
            margin-top: 2rem;
        }
    }
    @media only screen and (max-width: 685px){
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .tab-head{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .tab-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sp-inner-tl h2{
            font-size: 1.5rem;
        }
    }
    @media only screen and (max-width: 391px){
        .sp-inner-tl h2{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>