<template>
    <section>
        <div class="section-content">
            <div class="sc-inner">
                <div class="sci-left">
                    <img src="../../assets/images/picture/imghh.png" alt="p3k melinda">
                </div>
                <div class="sci-right">
                    <p class="sm-title">RS Melinda 2</p>
                    <h2>History of P3K Melinda Hospital</h2>
                    <p>Rumah Sakit Melinda 2 terletak di pusat kota Bandung yang strategis, yaitu di Jl. Dr Cipto No.1 Bandung. Rumah Sakit Melinda 2 merupakan rumah sakit umum yang memiliki pelayanan yang unggul, baik di  dalam bidang medis maupun non-medis.</p>
                    <p>Profesionalisme yang tinggi, kerja keras, kedisiplinan, serta kerja sama tim yang baik mampu menghasilkan kesuksesan untuk Rumah Sakit Melinda 2 dalam memenuhi standar Internasional, baik dalam kesehatan maupun keselamatan pasien.</p>
                    <p>Rumah Sakit Melinda 2, hadir sejak Juni 2014. Rumah Sakit Melinda 2 memiliki bangunan lima lantai dan dua basement yang luas totalnya mencapai 3000 meter persegi.</p>
                    <p>Rumah Sakit Melinda 2 menyediakan berbagai fasilitas kesehatan dengan peralatan medis yang canggih dan terbaru, serta didukung oleh staf medis yang profesional untuk mengoperasikan peralatan tersebut. Selain itu, Rumah Sakit Melinda 2 memiliki lebih dari  90 dokter dan staff ahli serta 140 perawat yang selalu siaga untuk membantu pasien.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutHistory',
    methods: {
        // 
    }
}
</script>

<style scoped>
    section{
        width: 100%;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #FEF8F0;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
    }
    section p{
        color: #575757;
        margin-top: 1rem;
        font-size: 1.125rem;
    }
    .section-header{
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
        transition: 0.2s;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
    }
    .section-content{
        width: 100%;
        height: 100%;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .sci-left{
        width: 50%;
        color: #575757;
        transition: 0.2s;
    }
    .sci-left img{
        width: 100%;
        max-width: 500px;
    }
    .sci-right{
        width: 50%;
        padding: 2rem;
        padding-top: 0;
        padding-left: 5rem;
        padding-bottom: 0;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sc-inner{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .sci-left{
            width: 26%;
        }
        .sci-right{
            width: 74%;
        }
    }
    @media only screen and (max-width: 685px){
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sc-inner{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-left{
            width: 100%;
        }
        .sci-right{
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
            padding-right: 0;
        }
    }
</style>