<template>
    <section>
        <div class="section-header">
            <h2 class="section-title">Cari berdasarkan abjad</h2>
            <div class="sh-alphabet">
                <div class="sha-button shab-active" id="sha-button-a" @click="setMedicines('a', 0, this.limit); setIndexValue('a');">A</div>
                <div class="sha-button" id="sha-button-b" @click="setMedicines('b', 0, this.limit); setIndexValue('b');">B</div>
                <div class="sha-button" id="sha-button-c" @click="setMedicines('c', 0, this.limit); setIndexValue('c');">C</div>
                <div class="sha-button" id="sha-button-d" @click="setMedicines('d', 0, this.limit); setIndexValue('d');">D</div>
                <div class="sha-button" id="sha-button-e" @click="setMedicines('e', 0, this.limit); setIndexValue('e');">E</div>
                <div class="sha-button" id="sha-button-f" @click="setMedicines('f', 0, this.limit); setIndexValue('f');">F</div>
                <div class="sha-button" id="sha-button-g" @click="setMedicines('g', 0, this.limit); setIndexValue('g');">G</div>
                <div class="sha-button" id="sha-button-h" @click="setMedicines('h', 0, this.limit); setIndexValue('h');">H</div>
                <div class="sha-button" id="sha-button-i" @click="setMedicines('i', 0, this.limit); setIndexValue('i');">I</div>
                <div class="sha-button" id="sha-button-j" @click="setMedicines('j', 0, this.limit); setIndexValue('j');">J</div>
                <div class="sha-button" id="sha-button-k" @click="setMedicines('k', 0, this.limit); setIndexValue('k');">K</div>
                <div class="sha-button" id="sha-button-l" @click="setMedicines('l', 0, this.limit); setIndexValue('l');">L</div>
                <div class="sha-button" id="sha-button-m" @click="setMedicines('m', 0, this.limit); setIndexValue('m');">M</div>
                <div class="sha-button" id="sha-button-n" @click="setMedicines('n', 0, this.limit); setIndexValue('n');">N</div>
                <div class="sha-button" id="sha-button-o" @click="setMedicines('o', 0, this.limit); setIndexValue('o');">O</div>
                <div class="sha-button" id="sha-button-p" @click="setMedicines('p', 0, this.limit); setIndexValue('p');">P</div>
                <div class="sha-button" id="sha-button-q" @click="setMedicines('q', 0, this.limit); setIndexValue('q');">Q</div>
                <div class="sha-button" id="sha-button-r" @click="setMedicines('r', 0, this.limit); setIndexValue('r');">R</div>
                <div class="sha-button" id="sha-button-s" @click="setMedicines('s', 0, this.limit); setIndexValue('s');">S</div>
                <div class="sha-button" id="sha-button-t" @click="setMedicines('t', 0, this.limit); setIndexValue('t');">T</div>
                <div class="sha-button" id="sha-button-u" @click="setMedicines('u', 0, this.limit); setIndexValue('u');">U</div>
                <div class="sha-button" id="sha-button-v" @click="setMedicines('v', 0, this.limit); setIndexValue('v');">V</div>
                <div class="sha-button" id="sha-button-w" @click="setMedicines('w', 0, this.limit); setIndexValue('w');">W</div>
                <div class="sha-button" id="sha-button-x" @click="setMedicines('x', 0, this.limit); setIndexValue('x');">X</div>
                <div class="sha-button" id="sha-button-y" @click="setMedicines('y', 0, this.limit); setIndexValue('y');">Y</div>
                <div class="sha-button" id="sha-button-z" @click="setMedicines('z', 0, this.limit); setIndexValue('z');">Z</div>
            </div>
        </div>
        <div class="section-body">
            <h3 class="section-title-sub">Pencarian Obat  “<span id="sts-index">A</span>”</h3>
            <div class="sb-inner" v-if="pageCount > 0">
                <div class="medicine-list" v-for="(medicine) in medicines" :key="medicine.id">
                    <div class="medicine-list-inner">
                        <router-link :to="'/obat/detail/' + medicine.id" >{{ medicine.name }}</router-link>
                    </div>
                </div>
            </div>
            <div class="sb-inner" v-else>
                <p>Tidak ditemukan obat dengan kata kunci "{{ indexNow.toUpperCase() }}"</p>
            </div>
        </div>
        <div class="section-footer">
            <div class="sf-page" v-if="pageCount > 0">
                <div class="sfp-button" @click="prevPage" v-if="pageNow > 1">
                    <span>Prev</span>
                </div>
                <div class="sfp-button sfp-disabled" v-else>
                    <span>Prev</span>
                </div>

                <div class="sfp-button sfpb-num" @click="prevPage" v-if="pageNow > 1">
                    <span>{{ pageMin }}</span>
                </div>
                <div class="sfp-button sfpb-num sfpb-active">
                    <span>{{ pageNow }}</span>
                </div>
                <div class="sfp-button sfpb-num" @click="nextPage" v-if="pageCount > pageNow">
                    <span>{{ pagePlus }}</span>
                </div>

                <div class="sfp-button" @click="nextPage" v-if="pageCount > pageNow">
                    <span>Next</span>
                </div>
                <div class="sfp-button sfp-disabled" v-else>
                    <span>Next</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useMedicineStore } from "../../stores/medicine";
    export default {
        name: 'MedicineIndex',
        data() {
            return {
                medicines: [],
                medCount: computed(() => useMedicineStore().medCount),
                pageCount: computed(() => useMedicineStore().pageCount),
                pageMin: 0,
                pageNow: 0,
                pagePlus: 0,
                indexNow: computed(() => useMedicineStore().index),
                offset: 0,
                limit: 40,
                id: this.$route.params.id
            }
        },
        methods: {
            setMedicines(index, offset, limit){
                useMedicineStore().fetchMedicinesByIndex(index, limit);
                useMedicineStore().filterMedicine(offset, limit);
                this.medicines = computed(() => useMedicineStore().activeMedicine);
                if(offset == 0){
                    this.pageNow = 1;
                }else{
                    this.pageNow = this.pageNow + 1;
                    this.pagePlus = this.pageNow;
                }
                this.pagePlus = this.pageNow + 1;
                useMedicineStore().setIndex(index);
                document.getElementById('input-search-medicines').value = '';
            },
            prevPage(){
                const os = this.offset - this.limit;
                const ol = os + this.limit;
                useMedicineStore().filterMedicine(os, ol);
                this.pageNow = this.pageNow - 1;
                this.pageMin = this.pageNow - 1;
                this.pagePlus = this.pageNow + 1;
                this.offset = this.offset - this.limit;
            },
            nextPage(){
                const os = this.offset + this.limit;
                const ol = os + this.limit;
                useMedicineStore().filterMedicine(os, ol);
                this.pageNow = this.pageNow + 1;
                this.pageMin = this.pageNow - 1;
                this.pagePlus = this.pageNow + 1;
                this.offset = this.offset + this.limit;
            },
            setIndexValue(index){
                document.getElementById('sts-index').innerHTML = index.toUpperCase();
                this.offset = 0;
                var shbtn = document.getElementsByClassName('sha-button');
                var i;
                for(i = 0; i < shbtn.length; i++){
                    shbtn[i].classList.remove('shab-active');
                }
                document.getElementById('sha-button-' + index).classList.add('shab-active');
            }
        },
        setup() {
            // 
        },
        mounted() {
            this.setMedicines('a', this.offset, this.limit);
        }
    }
</script>

<style scoped>
    section{
        width: 100%;
        padding-top: 2.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        /* overflow: hidden; */
        transition: 0.2s;
    }
    .section-title{
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    .sh-alphabet{
        display: flex;
        flex-wrap: wrap;
        transition: 0.2s;
    }
    .sh-alphabet::-webkit-scrollbar{
        display: none;
    }
    .sha-button{
        border: 1px solid lightgrey;
        border-radius: 10px;
        min-width: 50px;
        width: 2.75rem;
        min-height: 50px;
        height: 2.75rem;
        display: flex;
        justify-content: center;
        padding-top: 0.75rem;
        margin-top: 1rem;
        margin-right: 1rem;
        cursor: pointer;
        transition: 0.2s;
    }
    .sha-button:hover{
        background: #93d0ee;
        color: #ffffff;
        border: 1px solid #93d0ee;
    }
    .shab-active{
        background: #75C6EF;
        color: #ffffff;
        border: 1px solid #75C6EF;
    }
    .section-body{
        width: 100%;
    }
    .section-title-sub{
        color: #575757;
        font-size: 1rem;
        font-weight: 500;
        margin-top: 2rem;
    }
    .sb-inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 33rem;
        max-height: 33rem;
        margin-top: 2rem;
        transition: 0.2s;
        overflow: auto;
        /* border: 1px solid green; */
    }
    .sb-inner::-webkit-scrollbar{
        display: none;
    }
    .sb-inner p{
        text-align: left;
        font-style: italic;
        color: lightgrey;
    }
    .medicine-list{
        width: 25%;
        /* margin-right: 2rem; */
        padding-top: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        transition: 0.2s;
        /* border: 1px solid gold; */
    }
    .medicine-list-inner{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .medicine-list-inner a{
        color: #575757;
        text-decoration: none;
        font-weight: 700;
    }
    .medicine-list a:hover{
        color: #7e7e7e;
    }
    .section-footer{
        width: 100%;
        height: 10rem;
        padding-top: 2rem;
    }
    .sf-page{
        display: flex;
        justify-content: center;
        user-select: none;
    }
    .sfp-button{
        display: flex;
        justify-content: center;
        padding-top: 0.36rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid #FCE8CE;
        border-radius: 5rem;
        margin: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
    }
    .sfpb-num{
        width: 2rem;
        height: 2rem;
    }
    .sfp-button:hover{
        background: #75C6EF;
        color: #ffffff;
        border: 1px solid #75C6EF;
    }
    .sfp-disabled{
        cursor: not-allowed;
        border: 1px solid whitesmoke;
        color: grey;
        font-style: italic;
    }
    .sfp-disabled:hover{
        background: #ffffff;
        color: grey;
        border: 1px solid whitesmoke;
    }
    .sfpb-active{
        background: #75C6EF;
        color: #ffffff;
        border: 1px solid #75C6EF;
        cursor: unset;
    }
    @media only screen and (max-width: 1410px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        section{
            padding-top: 3.6rem;
        }
        .sh-alphabet{
            flex-wrap: nowrap;
            overflow: auto;
        }
    }
    @media only screen and (max-width: 685px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sb-inner{
            height: 33rem;
            max-height: 33rem;
            overflow: auto;
        }
        .sb-inner::-webkit-scrollbar{
            display: block;
            height: 5px;
            border-radius: 5px;
        }
        .medicine-list{
            width: 45%;
        }
    }
</style>