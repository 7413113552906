import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useFirstAidStore = defineStore("firstaid", {
  state: () => ({
    firstaids: null,
    articleNum: 1,
    firstAidDetails: null,
    articleCategories: null,
    exploreData: [],
    firstAidsByCategory: null,
    activeCategory: null,
    atxeb1: null,
    atxeb2: null,
    atxeb3: null,
    atxeb4: null,
    atxeb5: null,
    atxeb6: null
  }),
  getters: {
    epData1: (state) => {
      return state.exploreData.slice(0, 1);
      // console.log(state.exploreData.slice(0, 1));
    }
  },
  actions: {
    setArticleNum(nm){
      this.articleNum = nm
    },
    async fetctFirstAids(limit) {
      const response = await axiosClient.get(`first-aid/get-all/` + limit);
      this.firstaids = response.data;
    },
    async fetchFirstAidDetails(id) {
      const response = await axiosClient.get(`first-aid/detail/` + id);
      this.firstAidDetails = response.data;
      // console.log(this.response.data);
    },
    async fetchArticleCategories() {
      const response = await axiosClient.get(`artikel/get-categories`);
      this.articleCategories = response.data;
    },
    async fetchExploreData(limit) {
      const response = await axiosClient.get(`first-aid/explore/` + limit);
      this.exploreData = response.data;
    },
    async fetchFabc(category, limit) {
      const response = await axiosClient.get(`first-aid/category/` + category + `/` + limit);
      this.firstAidsByCategory = response.data;
      this.activeCategory = response.data[0].category_name;
    },
    async fetchArticlesHb7(category, limit) {
      const response = await axiosClient.get(`first-aid/category/` + category + `/` + limit);
      this.articlesHb7 = response.data;
    },
    async fetchAtxkc1(category, limit) {
      const response = await axiosClient.get(`first-aid/category/` + category + `/` + limit);
      this.atxeb1 = response.data;
    },
    async fetchAtxkc2(category, limit) {
      const response = await axiosClient.get(`first-aid/category/` + category + `/` + limit);
      this.atxeb2 = response.data;
    },
    async fetchAtxkc3(category, limit) {
      const response = await axiosClient.get(`first-aid/category/` + category + `/` + limit);
      this.atxeb3 = response.data;
    },
    async fetchAtxkc4(category, limit) {
      const response = await axiosClient.get(`first-aid/category/` + category + `/` + limit);
      this.atxeb4 = response.data;
    },
    async fetchAtxkc5(category, limit) {
      const response = await axiosClient.get(`first-aid/category/` + category + `/` + limit);
      this.atxeb5 = response.data;
    },
    async fetchAtxkc6(category, limit) {
      const response = await axiosClient.get(`first-aid/category/` + category + `/` + limit);
      this.atxeb6 = response.data;
    }
  },
});