import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

createApp(App).use(createPinia()).use(router).mount('#app')

window.onclick = function(e){
    var i;
    if(!e.target.classList.contains('float-button')){
        const float = document.getElementsByClassName('float');
        const icon = document.getElementsByClassName('tbi-img');
        for(i = 0; i < float.length; i++){
            float[i].style.display = 'none';
        }
        for(i = 0; i < icon.length; i++){
            if(icon[i].style.transform == 'rotate(541deg)'){
                icon[i].style.transform = 'rotate(0)';
            }
        }
    }
    if(!e.target.classList.contains('float-button-mobile')){
        if(screen.width <= 685){
            const float = document.getElementsByClassName('float-mobile');
            const icon = document.getElementsByClassName('tbi-img');
            for(i = 0; i < float.length; i++){
                float[i].style.display = 'none';
            }
            for(i = 0; i < icon.length; i++){
                if(icon[i].style.transform == 'rotate(540deg)'){
                    icon[i].style.transform = 'rotate(0)';
                }
            }
        }
    }
}
window.onresize = function(){
    const mobres = document.getElementsByClassName('mobile-noflex');
    var i;
    if(screen.width <= 685){
        for(i = 0; i < mobres.length; i++){
            mobres[i].style.display = 'none';
        }
        const icon = document.getElementsByClassName('tbi-img');
        for(i = 0; i < icon.length; i++){
            if(icon[i].style.transform == 'rotate(540deg)'){
                icon[i].style.transform = 'rotate(0)';
            }
        }
    }else{
        for(i = 0; i < mobres.length; i++){
            mobres[i].style.display = 'flex';
        }
    }
}
let touchstartX = 0
let touchendX = 0
const nbmob = document.getElementById('navbar-menu');
const nbmobbg = document.getElementById('navbar-menu-bg');
function handleGesture() {
    if(touchendX > touchstartX){
        document.getElementById('navbar-menu').style.left = '110%';
        document.getElementById('navbar-menu-bg').style.width = '0';
    }
}
nbmob.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX
});
nbmob.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX
    handleGesture();
});
nbmobbg.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX
});
nbmobbg.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX
    handleGesture();
});