<template>
    <div class="home-header">
        <div class="hh-bg"></div>
        <div class="hh-inner">
            <div class="hh-part hh-left">
                <div class="hh-left-inner animate__animated animate__backInLeft animate__delay-0.2s">
                    <h1 class="p-1">
                        <strong>Melinda Hospital P3K</strong>
                    </h1>
                    <p class="welcome-text">Welcome to P3K Directory</p>
                    <p class="p-2">Feel the relax and sensations of staying in Melinda Hospital room suite, this room large space priorities technology and high security</p>
                </div>
                <div class="hh-search">
                    <div class="hhs-input">
                        <input type="text" placeholder="What will you learn on P3K Melinda Hospital today?" />
                    </div>
                    <div class="hhs-button">
                        <span>Search</span>
                    </div>
                </div>
            </div>
            <div class="hh-part hh-right">
                <img src="../../assets/imghh.png" alt="Masakan Nusantara">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeHeader'
}
</script>

<style scoped>
    .home-header{
        position: relative;
        width: 100%;
        height: 36rem;
        padding-top: 7.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .hh-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 95%;
        background: #1B4674;
        z-index: -1;
        transition: 0.2s;
    }
    .hh-inner{
        display: flex;
        width: 100%;
        height: 100%;
    }
    .hh-part{
        width: 50%;
        height: 100%;
        transition: 0.2s;
    }
    .hh-left{
        color: #ffffff;
        padding-right: 6.69rem;
        padding-bottom: 0;
        transition: 0.2s;
    }
    .p-1{
        font-size: 1.125rem;
        font-weight: 600;
        color: #75C6EF;
        margin: 0;
    }
    .welcome-text{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        width: 22.56rem;
        margin: 0;
        margin-top: 1.37rem;
        transition: 0.2s;
    }
    .p-2{
        margin-top: 2.2rem;
    }
    .hh-search{
        display: flex;
        flex-wrap: nowrap;
        background: #ffffff;
        width: 100%;
        height: 5.0625rem;
        margin-top: 6.9375rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        transition: 0.2s;
    }
    .hhs-input{
        width: 75%;
        height: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .hhs-input input{
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 1rem;
        padding-left: 2rem;
        font-size: 1rem;
        color: #575757;
        transition: 0.2s;
    }
    .hhs-input input::placeholder{
        color: #575757;
    }
    .hhs-input input:-ms-input-placeholder{
        color: #575757;
    }
    .hhs-input input::-ms-input-placeholder{
        color: #575757;
    }
    .hhs-button{
        width: 25%;
        height: 100%;
        background: #EA476A;
        text-align: center;
        padding-top: 1.75rem;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .hh-right{
        text-align: right;
        transition: 0.2s;
    }
    .hh-right img{
        width: 100%;
        max-width: 750px;
    }
    @media only screen and (min-width: 2478px){
        .hh-search{
            margin-top: 7.9rem;
        }
    }
    @media only screen and (max-width: 1544px){
        .hh-search{
            margin-top: 6rem;
        }
    }
    @media only screen and (max-width: 1450px){
        .hh-part{
            position: relative;
        }
        .hh-search{
            position: relative;
            width: 175%;
            height: 4.5rem;
            z-index: 1;
            margin-top: 6.7rem;
        }
        .hhs-input input{
            padding: 5%;
        }
    }
    @media only screen and (max-width: 1410px){
        .home-header{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1280px){
        .hh-search{
            margin-top: 6rem;
        }
    }
    @media only screen and (max-width: 1000px){
        .home-header{
            height: auto;
            padding-top: 2rem;
        }
        .hh-bg{
            height: 100%;
        }
        .hh-search{
            margin-top: 5rem;
            margin-bottom: 2rem;
        }
    }
    @media only screen and (max-width: 700px){
        .home-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
            height: auto;
        }
        .hh-bg{
            height: 100%;
        }
        .hh-inner{
            height: auto;
        }
        .hh-part{
            width: 100%;
            height: auto;
        }
        .hh-left{
            padding-right: 0;
            height: auto;
        }
        .hh-search{
            width: 100%;
            height: 3.6rem;
            margin-top: 2rem;
        }
        .hhs-input{
            width: 70%;
        }
        .hhs-button{
            width: 30%;
            padding-top: 1rem;
        }
        .welcome-text{
            width: 100%;
            font-size: 2rem;
            margin-top: 1rem;
        }
        .hh-right{
            width: 0;
        }
    }
</style>