<template>
    <div class="content">
        <ArticleContent />
        <ArticleRelated />
    </div>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../stores/article";
    import ArticleContent from '@/components/article/ArticleContent.vue'
    import ArticleRelated from '@/components/article/ArticleRelated.vue'
    export default {
        name: 'ArticleDetailPage',
        data() {
            return {
                id: this.$route.params.id,
                title: computed(() => useArticleStore().articleDetailTitle)
            }
        },
        components: {
            ArticleContent,
            ArticleRelated
        },
        mounted(){
            document.title = this.title
        }
    }
</script>