<template>
    <section>
        <div class="section-header">
            <h2>Related Articles</h2>
        </div>
        <div class="section-content">
            <div class="section-inner">
                <div class="sci-part" v-for="(article, index) in related" :key="article.id">
                    <div class="sp-inner" v-if="index % 2 == 0">
                        <p class="sp-category">{{ article.name }}</p>
                        <router-link :to="detailpath + article.id" @click="setNewArticle(article.id)" >
                            <h3>{{ article.title }}</h3>
                        </router-link>
                        <div class="sp-author">
                            <div class="spa-icon">
                                <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                            </div>
                            <div class="spa-text">
                                <p>Ahmad Pratama on 22 July 2022</p>
                            </div>
                        </div>
                        <div class="sp-description">
                            <p>{{ article.meta_description }}</p>
                        </div>
                        <div class="sp-more">
                            <router-link :to="detailpath + article.id" @click="setNewArticle(article.id)" >Readmore</router-link>
                        </div>
                    </div>
                    <div class="sp-inner spi-even" v-else>
                        <p class="sp-category">{{ article.name }}</p>
                        <router-link :to="detailpath + article.id" @click="setNewArticle(article.id)" >
                            <h3>{{ article.title }}</h3>
                        </router-link>
                        <div class="sp-author">
                            <div class="spa-icon">
                                <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda" />
                            </div>
                            <div class="spa-text">
                                <p>Ahmad Pratama on 22 July 2022</p>
                            </div>
                        </div>
                        <div class="sp-description">
                            <p>{{ article.meta_description }}</p>
                        </div>
                        <div class="sp-more">
                            <router-link :to="detailpath + article.id" @click="setNewArticle(article.id)" >Readmore</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    export default {
        name: 'ArticleRelated',
        data() {
            return {
                related: [],
                id: this.$route.params.id,
                detailpath: '/article/detail/',
                imgsrc: process.env.VUE_APP_ROOT_API + 'images/articles/'
            }
        },
        methods: {
            fetchRelated(){
                if(useArticleStore().articleDetailRelated.length < 1){
                    useArticleStore().fetchAdr(this.id);
                }
                this.related = computed(() => useArticleStore().adRelated);
            },
            setNewArticle(id){
                useArticleStore().fetchArticleDetail(id);
                useArticleStore().fetchAdr(id);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        },
        mounted() {
            this.fetchRelated();
        }
    }
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 36rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
    }
    section h2{
        font-family: poppins;
        font-size: 1.75rem;
        font-weight: 700;
        margin: 0;
    }
    .section-header{
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 0.5rem;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 7.25rem;
        padding-right: 12.25rem;
    }
    .section-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .sci-part{
        width: 50%;
    }
    .sci-part h3{
        font-family: poppins;
        font-weight: 700;
        font-size: 2rem;
        margin: 0;
    }
    .sp-inner{
        padding: 5rem;
        /* padding-right: 5rem; */
    }
    .sp-inner a{
        color: #1B4674;
        text-decoration: none;
    }
    .spi-even{
        background: #FEF8F0;
    }
    .sp-category{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
    }
    .sp-author{
        display: flex;
        margin-top: 1rem;
    }
    .spa-icon{
        width: 50px;
    }
    .spa-icon img{
        background: orange;
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    .spa-text{
        color: #575757;
        margin-left: 1rem;
        font-size: 0.875rem;
        font-weight: 700;
    }
    .sp-description{
        color: #575757;
    }
    .sp-more a{
        text-decoration: underline;
        color: #575757;
        font-weight: 600;
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sp-inner{
            padding: 5%;
            padding-left: 0;
        }
        .spi-even{
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .sci-part h3{
            font-size: 1.5rem;
        }
    }
    @media only screen and (max-width: 710px){
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-part{
            width: 100%;
        }
    }
</style>