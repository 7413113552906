<template>
    <section>
        <div class="section-content">
            <div class="sc-inner">
                <!-- {{ medicine }} -->
                <div class="sci-left">
                    <div class="sl-head">
                        <h1 class="main-title">{{ medicine.name }}</h1>
                        <div class="sh-label">
                            <div class="sh-label-part">Urinter</div>
                            <div class="sh-label-part">Urixin</div>
                            <div class="sh-label-part">Urotractin</div>
                        </div>
                    </div>
                    <div class="sl-body">
                        <div class="sl-content" v-html="medicine.description"></div>
                        <div class="side-effect">
                            <h2>Efek Samping</h2>
                            <p v-html="medicine.side_effect"></p>
                        </div>
                        <div class="warning-note">
                            <h2>Catatan</h2>
                            <p v-html="medicine.side_effect"></p>
                        </div>
                    </div>
                    <div class="sc-more">
                        <router-link to="/">Load more detail</router-link>
                    </div>
                </div>
                <div class="sci-right">
                    <div class="sr-author">
                        <p class="rv-by">Ditinjau oleh :</p>
                        <div class="sra-head">
                            <div class="sra-icon">
                                <img src="../../assets/images/picture/imghl1.png" alt="p3k melinda">
                            </div>
                            <div class="sra-text">
                                <h2>dr. Merry Dame Cristy Pane</h2>
                            </div>
                        </div>
                        <div class="sra-cu">
                            <div class="sra-part sra-part-cr">
                                <p class="sra-part-param">Updated:</p>
                                <p class="sra-part-value">May 6, 2021</p>
                            </div>
                            <div class="sra-part sra-part-up">
                                <p class="sra-part-param">Views</p>
                                <p class="sra-part-value">89721</p>
                            </div>
                        </div>
                    </div>
                    <div class="sr-guide">
                        <h2>Petunjuk Pemakaian</h2>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Baca kandungan, aturan, dan petunjuk penggunaan obat sebelum meminumnya.</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Selalu diskusikan dengan dokter mengenai alergi yang Anda miliki, sebelum mulai mengonsumsi obat apa pun.</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Selalu diskusikan dengan dokter mengenai obat-obatan yang sedang Anda konsumsi, sebelum mulai mengonsumsi obat baru.</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Selalu diskusikan dengan dokter mengenai riwayat kesehatan Anda, sebelum mulai mengonsumsi obat baru.</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Jangan mengonsumsi suatu obat jika alergi terhadap obat tersebut atau sejenisnya, sebelum membicarakannya dengan dokter.</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Konsultasikan dengan dokter jika ragu mengenai pemakaian obat.</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Jauhkan obat dari jangkauan anak-anak.</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="hc-list">
                            <div class="hcl-icon">
                                <img src="../../assets/images/icons/cvrightred.png" alt="p3k melinda">
                            </div>
                            <div class="hcl-text">
                                <router-link to="/">
                                    <span>Hindarkan obat dari paparan panas maupun sinar matahari secara langsung.</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { computed } from "@vue/runtime-core";
    import { useMedicineStore } from "../../stores/medicine";
    export default {
        name: 'MedicineContent',
        data() {
            return {
                medicine: {},
                id: this.$route.params.id
            }
        },
        methods: {
            setMedicine(){
                useMedicineStore().fetchMedicine(this.id);
                this.medicine = computed(() => useMedicineStore().medicine);
            }
        },
        mounted() {
            this.setMedicine();
        }
    }
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #ffffff;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        color: #1B4674;
        line-height: 1.1;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        margin-top: 2rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .sci-left{
        width: 60%;
        color: #575757;
        transition: 0.2s;
    }
    .sh-label{
        display: flex;
        flex-wrap: wrap;
    }
    .sh-label-part{
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #FCE8CE;
        color: #575757;
        margin-right: 1rem;
        border-radius: 5rem;
    }
    .sl-body{
        padding-top: 2rem;
        text-align: justify;
    }
    .sl-content{
        line-height: 1.5;
        font-size: 1.2rem;
    }
    .side-effect{
        font-size: 1.2rem;
    }
    .warning-note{
        font-size: 1.2rem;
    }
    .sc-more{
        margin-top: 1.6875rem;
        text-align: center;
    }
    .sc-more a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    .sci-right{
        width: 40%;
        /* padding-top: 15rem; */
        padding-left: 5rem;
        transition: 0.2s;
    }
    .sr-author{
        background: #ffffff;
        /* min-height: 10rem; */
        width: 100%;
        padding: 2rem;
        margin-bottom: 2rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
        border-radius: 5px;
    }
    .rv-by{
        color: #575757;
        font-size: 0.875rem;
    }
    .sra-head{
        display: flex;
        flex-wrap: nowrap;
    }
    .sra-icon{
        min-width: 50px;
        width: 3.125rem;
        min-height: 50px;
        height: 3.125rem;
    }
    .sra-icon img{
        width: 100%;
        height: 100%;
        background: orange;
        border-radius: 3.125rem;
    }
    .sra-text{
        padding-left: 1rem;
    }
    .sra-text h2{
        font-size: 1.25rem;
    }
    .sra-cu{
        display: flex;
        margin-top: 2rem;
        color: #575757;
    }
    .sra-part{
        width: 50%;
    }
    .sra-part-cr{
        border-right: 1px solid #E4E4E4;
    }
    .sra-part-value{
        font-weight: 700;
        margin-top: 0.5rem;
    }
    .sra-part-up{
        padding-left: 2rem;
    }
    .sr-guide{
        background: #FEF8F0;
        padding: 2rem;
        width: 100%;
    }
    .sr-guide h2{
        margin-bottom: 0.5rem;
    }
    .sr-guide a{
        color: #575757;
        text-decoration: none;
    }
    .hc-list{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 0.5rem;
        padding-top: 0.5rem;
    }
    .hcl-icon{
        min-width: 10px;
        width: 0.5rem;
        padding-top: 0.1rem;
    }
    .hcl-icon img{
        width: 100%;
    }
    .hcl-text{
        width: 85%;
        padding-left: 1rem;
    }
    @media only screen and (max-width: 1410px){
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .section-content{
            margin-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .main-title{
            font-size: 2.6rem;
            margin-top: 1%;
            margin-bottom: 2rem;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-left: 0;
            margin-top: 2rem;
        }
    }
    @media only screen and (max-width: 710px){
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
    }
</style>