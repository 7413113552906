import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useMedicineStore = defineStore("medicine", {
  state: () => ({
    medicinesByIndex: [],
    activeMedicine: [],
    medicine: {},
    medCount: 0,
    pageCount: 0,
    pageMin: 0,
    pageNow: 0,
    pagePlus: 0,
    index: 'a'
  }),
  getters: {
    medicineActive: (state) => (offset, limit) => {
      return state.medicinesByIndex.slice(offset, limit);
    }
  },
  actions: {
    setIndex(index){
      this.index = index;
    },
    filterMedicine(offset, limit){
      this.activeMedicine = this.medicinesByIndex.slice(offset, limit);
    },
    async fetchMedicinesByIndex(index, limit) {
      if(index.trim().length > 0){
        const response = await axiosClient.get(`medicine/index/` + index);
        this.medicinesByIndex = response.data;
        this.filterMedicine(0, limit);
        this.medCount = response.data.length;
        this.pageCount = Math.ceil(response.data.length / limit);
      }
    },
    async searchMedicines(keywords, limit) {
      const response = await axiosClient.get(`medicine/search/` + keywords);
      this.medicinesByIndex = response.data;
      this.filterMedicine(0, limit);
      this.medCount = response.data.length;
      this.pageCount = Math.ceil(response.data.length / limit);
    },
    async fetchMedicine(id) {
      const response = await axiosClient.get(`medicine/detail/` + id);
      this.medicine = response.data;
    }
  },
});