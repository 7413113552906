<template>
    <button @click="increment">Plus</button>
    <button @click="decrement">minus</button>
    <button @click="reset">Reset</button>
    <br/>
    <br/>
    <button @click="incrementWithAction">Increment With Action</button>
    <button @click="decrementWithAction">Decrement With Action</button>
    <button @click="incremenetByWithAction(5)">Increment By With Action</button>
    <hr/>
    <button @click="fetchPokemon('ditto')">Fetch Ditto</button>
</template>

<script setup>
import { useCounterStore } from "../stores/counter"
import { usePokemonStore } from "../stores/pokemon"

const counter = useCounterStore();
const pokemon = usePokemonStore();

const increment = () => {
    counter.$patch((state) => {
        state.count++;
    })
}
const decrement = () => {
    counter.$patch((state) => {
        state.count--;
    })
}
const reset = () => {
    counter.$reset()
}
const incrementWithAction = () => {
    counter.increment();
}
const decrementWithAction = () => {
    counter.decrement();
}
const incremenetByWithAction = (amount) => {
    counter.incrementBy(amount);
}
const fetchPokemon = (name) => {
    pokemon.getPokemonDetails(name);
}
</script>

<style>
</style>