<template>
    <section>
        <div class="section-header">
            <p class="sm-title">Melinda Hospital P3K</p>
            <h2>Featured Article</h2>
        </div>
        <HomeFeaturedSkeleton v-if="!artikel" />
        <div class="section-content" v-else>
            <div class="sc-inner" id="sc-articles">
                <div class="sci-part" v-for="article in artikel" :key="article.id">
                    <div class="sci-img">
                        <img v-bind:src="imgsrc + article.picture" alt="p3k melinda" />
                        <div class="sci-gradient"></div>
                    </div>
                    <div class="sci-label">
                        <span>{{ article.name }}</span>
                    </div>
                    <div class="sci-caption">
                        <div class="sci-caption-inner">
                            <router-link :to="detailpath + article.id" @click="setArticleNum(article.id)" >
                                <h3>{{ article.title }}</h3>
                            </router-link>
                            <p class="sci-caption-date">{{ epochDMY(Date.parse(article.created_at)) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sc-browse">
                <router-link to="" @click="loadMore('sc-articles')">Browse featured article</router-link>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    import HomeFeaturedSkeleton from '@/components/skeletons/HomeFeaturedSkeleton.vue'
    const store = useArticleStore();
    const artikel = computed(() => store.articles);
    store.fetchArticles(8);
    function loadMore(id){
        const plus = 4;
        store.fetchArticles(document.getElementById(id).children.length + plus);
    }
    function epochDMY(ts){
        const months = ["Januari", "Pebruari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        var dt = new Date(ts);
        var d = dt.getDay();
        var m = months[dt.getMonth()];
        var y = dt.getFullYear();
        return d + ' ' + m +' ' + y;
    }
    function setArticleNum(nm){
        store.setArticleNum(nm);
    }
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/articles/';
    const detailpath = '/article/detail/';
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 36rem;
        padding-top: 1rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
    }
    section p{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1.5rem;
    }
    .section-header{
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .section-content{
        width: 100%;
        height: 100%;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
        transition: 0.2s;
    }
    .sc-inner::-webkit-scrollbar{
        display: none;
    }
    .sci-part{
        position: relative;
        color: #ffffff;
        width: 25%;
        min-width: 19rem;
        min-height: 22rem;
        padding: 1%;
        transition: 0.2s;
    }
    .sci-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .sci-img img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-label{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 3rem;
        white-space: nowrap;
    }
    .sci-label span{
        background: #75C6EF;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
    }
    .sci-label-sp span{
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 5px;
    }
    .sci-caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        padding-top: 1.5rem;
    }
    .sci-caption-inner{
        padding: 1rem;
        /* padding-bottom: 1rem; */
        width: 100%;
        /* background-image: linear-gradient(transparent, #000000); */
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-caption a{
        text-decoration: none;
        color: #ffffff;
    }
    .sci-caption h3{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1.2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sci-caption-date{
        margin: 0;
        margin-top: 0.5rem;
    }
    .sc-browse{
        margin-top: 1.6875rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
    }
    .sc-browse a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1585px){
        .sci-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 1410px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sc-inner{
            padding-left: 5%;
            padding-right: 5%;
        }
        .sc-browse{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1015px){
        .sc-inner{
            flex-wrap: nowrap;
            overflow: auto;
        }
        .sci-part{
            min-width: 22rem;
            width: 50%;
        }
    }
    @media only screen and (max-width: 685px){
        section{
            padding-top: 0;
            min-height: unset;
        }
        section h2{
            font-size: 2rem;
        }
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
            padding-bottom: 1rem;
        }
        .sc-inner{
            padding-left: 1.5%;
            padding-right: 1.5%;
        }
        .sci-part{
            width: 100%;
        }
        .sci-label{
            padding-top: 2rem;
            padding-left: 1.5rem;
        }
        .sc-browse{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
    }
</style>