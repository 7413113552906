<template>
    <div class="content">
        <MedicineHeader />
        <MedicineIndex />
    </div>
</template>

<script setup>
    import MedicineHeader from '@/components/medicine/MedicineHeader.vue'
    import MedicineIndex from '@/components/medicine/MedicineIndex.vue'
  document.title = 'Medicine - P3K Melinda Hospital';
</script>