<template>
    <div class="content">
      <ExploreCategoryHeader />
      <ExploreCategoryContent />
    </div>
</template>

<script>
import ExploreCategoryHeader from '@/components/explore/ExploreCategoryHeader.vue'
import ExploreCategoryContent from '@/components/explore/ExploreCategoryContent.vue'
export default {
  name: 'ExplorePage',
  components: {
    ExploreCategoryHeader,
    ExploreCategoryContent,
  },
  data() {
    return {
      products: [],
    };
  },
  methods: {
    setProducts(data) {
      this.products = data;
    },
  }
}
</script>