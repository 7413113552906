<template>
    <section>
        <div class="section-bg"></div>
        <div class="home-header">
            <div class="hh-part">
                <p class="p-1">P3K</p>
                <h1>Daftar Obat</h1>
                <div class="hh-search">
                    <div class="hhs-input">
                        <input type="text" id="input-search-medicines" @input="searchMedicines" placeholder="Pencarian Obat" />
                    </div>
                    <div class="hhs-button">
                        <span>Search</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    // import { computed } from "@vue/runtime-core";
    import { useMedicineStore } from "../../stores/medicine";
    export default {
        name: 'MedicineHeader',
        methods: {
            searchMedicines(){
                const input = document.getElementById('input-search-medicines');
                var shbtn = document.getElementsByClassName('sha-button');
                var i;
                if(input.value.length > 0){
                    if(input.value == ' '){
                        input.value = '';
                    }else{
                        useMedicineStore().searchMedicines(input.value, 40);
                        useMedicineStore().filterMedicine(0, 40);
                        useMedicineStore().setIndex(input.value);
                        
                        for(i = 0; i < shbtn.length; i++){
                            shbtn[i].classList.remove('shab-active');
                        }
                        document.getElementById('sha-button-' + input.value.substring(0, 1)).classList.add('shab-active');
                        document.getElementById('sts-index').innerHTML = input.value.substring(0, 1).toUpperCase();
                    }
                }else{
                    for(i = 0; i < shbtn.length; i++){
                        shbtn[i].classList.remove('shab-active');
                    }
                    document.getElementById('sha-button-a').classList.add('shab-active');
                    useMedicineStore().fetchMedicinesByIndex('a', 40);
                    useMedicineStore().filterMedicine(0, 40);
                    useMedicineStore().setIndex('a');
                    document.getElementById('sts-index').innerHTML = input.value.substring(0, 1).toUpperCase();
                }
            }
        }
    }
</script>

<style scoped>
    section{
        position: relative;
    }
    .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 22rem;
        z-index: -1;
        background: #1B4674;
        transition: 0.2s;
    }
    .home-header{
        width: 100%;
    }
    .hh-part{
        width: 100%;
        height: 100%;
        color: #ffffff;
        padding: 7.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 0;
        transition: 0.2s;
    }
    .p-1{
        font-weight: 600;
        color: #75C6EF;
    }
    .hh-part h1{
        font-family: poppins;
        font-size: 3.25rem;
        width: 22.56rem;
        margin-top: 1.37rem;
        transition: 0.2s;
    }
    .hh-search{
        display: flex;
        flex-wrap: nowrap;
        background: #ffffff;
        width: 100%;
        height: 5.0625rem;
        margin-top: 3.6rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
        border-radius: 10px;
    }
    .hhs-input{
        width: 85%;
        height: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        transition: 0.2s;
    }
    .hhs-input input{
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 1rem;
        padding-left: 2rem;
        font-size: 1rem;
        color: #575757;
        transition: 0.2s;
    }
    .hhs-input input::placeholder{
        color: #575757;
    }
    .hhs-input input:-ms-input-placeholder{
        color: #575757;
    }
    .hhs-input input::-ms-input-placeholder{
        color: #575757;
    }
    .hhs-button{
        width: 15%;
        height: 100%;
        background: #EA476A;
        text-align: center;
        padding-top: 1.75rem;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 1.125rem;
        font-weight: 600;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1410px){
        .hh-part{
            padding-left: 5%;
            padding-right: 5%;
        }
        .hhs-input input{
            padding: 1%;
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .hh-part{
            padding-top: 2rem;
        }
        .hhs-input{
            width: 74%;
        }
        .hhs-button{
            width: 26%;
        }
    }
    @media only screen and (max-width: 710px){
        .section-bg{
            height: 16.5rem;
        }
        .hh-part{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .hh-part h1{
            font-size: 2.6rem;
            margin-bottom: 1rem;
            width: auto;
        }
        .hh-search{
            height: 3.6rem;
            margin-top: 1rem;
        }
        .hhs-input{
            width: 64%;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .hhs-button{
            width: 36%;
            font-size: 1rem;
            padding-top: 1.2rem;
        }
    }
</style>