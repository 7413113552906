<template>
  <!-- <div class="home"> -->
    <!-- <MainNavbar /> -->
    <div class="content">
        <ExperimentComponent />
        <ExperimentTwo />
    </div>
    <!-- <MainFooter /> -->
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import MainNavbar from '@/components/MainNavbar.vue'
import ExperimentComponent from '@/components/ExperimentComponent.vue'
import ExperimentTwo from '@/components/ExperimentTwo.vue'

// import MainFooter from '@/components/MainFooter.vue'


export default {
  name: 'HomePage',
  components: {
    // MainNavbar,
    ExperimentComponent,
    ExperimentTwo,
    // MainFooter
  }
}
</script>





